.dashboard-team-information {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06x);
  padding: var(--spacing-04x);
  border-radius: var(--border-r-xl);
  background-color: var(--color-bg-00);
}

.dashboard-team-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06x);
}

.input-field {
  width: 40%;
  min-width: 256px;
}

.label {
  font-size: var(--font-size-s);
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.avatar-container {
  display: flex;
  align-items: center;
}

.file-upload-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-s);
  padding-bottom: var(--spacing-02x);
  padding-left: var(--spacing-03x);
}

.team-name-container {
  display: flex;
  gap: var(--spacing-02x);
  align-items: flex-end;
}

.dashboard-team-delete {
  display: flex;
  justify-content: flex-end;
}

.divider {
  background-color: var(--color-bg-20);
  height: 1px;
  border: none;
  width: 100%;
  margin: 0;
}

.forno-avatar-wrapper[data-size='4'] div {
  height: 4rem;
  width: 4rem;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-100);
}

.forno-avatar-wrapper[data-size='4'] img {
  height: 4rem;
  width: 4rem;
}
