.app {
  background-color: var(--color-fg-90);
  padding: var(--spacing-04x);
}

.app article {
  display: flex;
  max-width: 500px;
  justify-content: space-around;
  align-items: flex-start;
  margin: var(--spacing-02x);
}
