.twoColumnSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-06x);
  text-align: center;
  padding: var(--spacing-12x) 0;
  max-width: 842px;
  margin: 0 auto;
}

.columns {
  display: flex;
  justify-content: center;
  gap: var(--spacing-16x);
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-03x);
}

.gifImage {
  max-width: 98px;
  height: auto;
}

@media (max-width: 768px) {
  .twoColumnSection {
    padding: var(--spacing-08x) 24px;
  }

  .columns {
    flex-direction: column;
  }

  .gifImage {
    max-width: 96px;
  }
}
