.profile-container {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: var(--font-size-s);
  background-color: transparent;
  border: none;
  width: 100%;
  color: inherit;
  padding: 0 var(--spacing-02x) 0 0;
  transition: all 0.2s ease;
  cursor: default;
}

.profile-container p {
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile-container.collapsed {
  justify-content: center;
}
