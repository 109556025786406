.login-page {
  background-color: var(--cb-10);
  color: var(--cb-80);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  gap: 160px;
  text-align: center;
}

.login-page h1 {
  font-family: var(--font-02);
}

.login-page-content-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: var(--spacing-06x);
  margin: 0 var(--spacing-04x);
}

.login-page button {
  width: 100%;
}

.login-disclaimer {
  font-size: var(--font-size-s);
}

.login-disclaimer a {
  color: var(--color-primary-01);
  text-decoration: none;
}
