.container {
  padding: var(--spacing-01x) var(--spacing-04x);
  background-color: var(--cb-90);
  border: 1.5px solid var(--color-primary-01);
  border-radius: var(--border-r-s);
  color: var(--color-bg-00);
  font-family: var(--font-family-inter);
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-03x);
}

.close-button {
  cursor: pointer;
}

.-danger {
  border-color: var(--color-danger-01);
}

.-danger .icon {
  stroke: var(--color-danger-01);
}

.-warning {
  border-color: var(--color-warning-01);
}

.-warning .icon {
  stroke: var(--color-warning-03);
}

.-success {
  border-color: var(--color-success-01);
}

.-success .icon {
  stroke: var(--color-success-01);
}

.-alert {
  border-color: var(--color-warning-01);
}

.-alert .icon {
  stroke: var(--color-warning-01);
}
