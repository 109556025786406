.missing-fonts-headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.missing-fonts-headline span {
  font-size: var(--font-size-s);
  line-height: var(--line-height-165);
}

.missing-fonts-headline span,
.missing-fonts-headline > svg:first-child {
  color: var(--color-warning-01);
}
