.editor-content {
  flex-grow: 1;
  background-color: var(--color-fg-60);
}

.editor-content-container {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
  height: 100%;
}

.preview-column-container {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-20);
}

.description-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-bottom: var(--spacing-04x);
}

.preview-column-container-variant {
  background-color: var(--color-bg-30);
}

.preview-column-container section {
  height: 60%;
  overflow-y: hidden;
}

.column-header {
  padding: var(--spacing-04x);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-02x);
  height: 180px;
}

.column-header > h3,
.column-header > p {
  width: 100%;
}

.column-header-title {
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  gap: var(--spacing-02x);
}

.column-header-title .cannoli-logo {
  width: 36px;
  color: var(--color-primary-01);
}

.buttons-container {
  display: flex;
  gap: var(--spacing-02x);
}

.message-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}

.message-container span {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-04x);
}
