.resources-page-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: var(--spacing-06x);
}
