.option {
  font-size: var(--font-size-s);
  padding: var(--spacing-02x);
}

.option-selected {
  border-left: solid 2px var(--color-secondary-01);
  background-color: var(--color-bg-10);
}

.option:hover {
  background-color: var(--color-bg-10);
  cursor: default;
}
