.user-form {
  display: flex;
  flex-flow: column;
  gap: var(--spacing-04x);
}

.user-form-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-04x);
  margin-bottom: var(--spacing-04x);
}

.field {
  flex: 1 1 40%;
}

.field input {
  width: 100%;
}

.user-form hr {
  margin: 0;
  background-color: var(--color-bg-20);
  height: 1px;
  border: none;
}
