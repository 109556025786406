.input-size-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-04x);
}

.input-size-container label {
  font-size: var(--font-size-s);
}

.input-size {
  width: 52px;
}

.input-size-container > div:first-child {
  min-width: var(--spacing-12x);
}
