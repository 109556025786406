.fallback-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  font-size: var(--font-size-s);
  line-height: var(--line-height-100);
}

.font-name {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}
