div[data-modal-type='fallback-stacks-admin'].fallback-stacks-modal {
  width: clamp(90%, 85vw, 1000px);
  max-width: 1000px;
}

div[data-modal-type='fallback-stacks-admin'].fallback-stacks-modal
  > section
  > div
  > div:nth-child(2) {
  height: 400px;
  overflow: auto;
}
