.block-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01x);
  cursor: pointer;
  width: 122px;
  height: 120px;
  align-items: center;
  border-radius: var(--border-r-s);
  transition: border 0.2s ease, filter 0.2s ease;
  border: solid var(--border-w-01) transparent;
  user-select: none;
}

.block-item-container[data-theme='light'] {
  background-color: var(--color-bg-10);
}

.block-item-container[data-theme='dark'] {
  background-color: var(--color-bg-30);
}

.block-item:hover {
  border: solid var(--border-w-01) var(--color-fg-40);
}

[data-selected='true'] {
  filter: brightness(0.8);
  background-color: var(--color-bg-20);
}

.block-item-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01x);
  border-radius: var(--border-r-s);
  width: 120px;
  height: fit-content;
}

.block-item-detail {
  width: 100%;
  display: flex;
  padding: var(--spacing-02x);
  color: var(--color-fg-50);
  justify-content: space-between;
  align-items: center;
}

.block-name-label {
  font-size: var(--font-size-s);
  pointer-events: none;
  max-width: var(--spacing-20x);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.block-item-iframe {
  width: 120px;
  height: 120px;
  border: none;
  overflow: hidden;
}

.block-checkbox-container {
  position: relative;
  width: var(--spacing-04x);
  height: var(--spacing-04x);
}

input[type='checkbox'] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: initial;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: initial;
  appearance: initial;
}

.block-checkbox {
  visibility: hidden;
  margin: 0;
  height: var(--spacing-04x);
  width: var(--spacing-04x);
}

.block-checkbox::after {
  content: '';
  display: block;
  width: var(--spacing-04x);
  height: var(--spacing-04x);
  border: var(--border-w-02) solid var(--color-fg-40);
  border-radius: var(--border-r-s);
  background-color: transparent;
  visibility: visible;
  pointer-events: none;
  transition: background-color 0.2s ease, color 0.2s ease;
}

[data-selected='true'] .block-checkbox::after {
  border: var(--border-w-02) solid var(--color-info-02);
  background-color: var(--color-info-01);
}

.block-check {
  position: absolute;
  visibility: hidden;
  color: var(--color-bg-00);
  stroke-width: 6px;
  top: 5px;
  left: var(--spacing-01x);
}

[data-selected='true'] .block-check {
  visibility: visible;
}
