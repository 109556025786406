.file-parsing-messages-wrapper {
  display: flex;
  flex-flow: column;
  border: 2px solid var(--color-fg-80);
  border-left: 0;
}

.file-parsing-message {
  display: flex;
  flex-flow: column;
  border-bottom: 2px solid var(--color-fg-80);
  border-left: 2px solid transparent;
  gap: 2px;
}

.file-parsing-message > div {
  padding: var(--spacing-02x);
}

.file-parsing-message footer {
  display: flex;
  flex-flow: row;
  gap: 2px;
  padding: 0 var(--spacing-02x);
  background: var(--color-fg-80);
}

.file-parsing-message footer span {
  background: var(--color-fg-80);
}
