.template-builder-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.help-info-container {
  display: flex;
  flex-direction: column;
  color: var(--color-fg-40);
}

.section-divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-fg-40);
  margin: var(--spacing-04x) 0;
}

.template-builder-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  height: 100%;
}

.template-builder {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  background-color: var(--color-bg-10);
  padding: var(--spacing-04x);
  border-radius: var(--border-r-m);
  height: 16rem;
  border: solid 1px var(--color-bg-20);
  overflow-y: auto;
}

.drag-indicator {
  cursor: grab;
}

.block-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--color-fg-40);
  padding: var(--spacing-02x);
}

.icons-container {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-02x);
  align-items: center;
}
