.pricing-card {
  color: var(--color-fg-70);
  background-color: var(--color-bg-00);
  display: flex;
  flex-direction: column;
  border-radius: var(--border-r-xl);
  box-sizing: border-box;
  outline: 1px solid var(--cb-20);
}

.pricing-card-highlight {
  background-color: var(--cannoli-secondary-02);
  border-radius: var(--spacing-04x) var(--spacing-04x) 0 0;
  width: 100%;
  color: var(--color-bg-00);
  text-align: center;
  padding: var(--spacing-02x);
}

.pricing-card-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.pricing-card-name {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.pricing-card-name > span {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
  font-size: var(--spacing-05x);
  font-weight: var(--font-weight-semi-bold);
  margin: 0;
}

.pricing-card-name p {
  font-size: var(--spacing-04x);
  line-height: var(--line-height-160);
}

.pricing-card-name .tag {
  border-color: var(--extra-color-01);
  background-color: rgba(1 84 211 / 10%);
  color: var(--color-info-01);
  margin: auto 0;
}

.pricing-card-name .badge {
  border-color: var(--extra-color-01);
  background-color: var(--color-warning-02);
  margin: auto 0;
  padding: var(--spacing-02x);
  border: none;
}

.pricing-card-pricing > span {
  font-size: var(--spacing-05x);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-160);
  margin: 0;
}

.pricing-card-pricing p {
  font-size: var(--spacing-03x);
  line-height: var(--line-height-100);
  margin: var(--spacing-02x) 0;
}

.pricing-card-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  padding: var(--spacing-04x) var(--spacing-08x);
  flex-grow: 1;
  justify-content: space-between;
}

.pricing-card-content > div {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.pricing-card-features {
  padding-left: var(--spacing-04x);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  margin: 0;
}

.pricing-card-features li {
  font-size: var(--spacing-04x);
  line-height: var(--line-height-160);
}

.pricing-card-content button {
  width: 100%;
}

.note {
  font-size: var(--spacing-03x);
  font-weight: var(--font-weight-semi-bold);
}
