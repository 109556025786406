.layout-children-menu-list[data-theme='dark'] {
  --color-bg-normal: var(--color-bg-20);
  --color-bg-hover: var(--color-bg-30);

  --color-fg-normal: var(--color-fg-60);
  --color-fg-danger: var(--color-danger-01);

  padding: var(--spacing-02x) var(--spacing-01x);
  background-color: var(--color-bg-normal);
  border-radius: var(--border-r-m);
  box-shadow: var(--shadow-01);
  display: flex;
  flex-direction: column;
  color: var(--color-fg-normal);
  gap: var(--spacing-01x);
  transition: background-color 0.2s ease;
}

.layout-children-menu-list[data-theme='dark'] .layout-children-menu-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-01x);
  transition: background-color 0.2s ease;
}

.layout-children-menu-list[data-theme='dark'] .layout-children-menu-item:hover {
  background-color: var(--color-bg-hover);
}

.layout-children-menu-list[data-theme='dark']
  .layout-children-menu-item-delete {
  color: var(--color-fg-danger);
  display: flex;
  align-items: center;
  gap: var(--spacing-01x);
  transition: background-color 0.2s ease;
}

.layout-children-menu-list[data-theme='dark']
  .layout-children-menu-item-delete:hover {
  background-color: var(--color-danger-subdued);
}

.layout-children-menu-button svg {
  pointer-events: none;
}

.layout-children-menu-icon {
  stroke-width: 10px;
}
