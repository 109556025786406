.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-secondary-subdued);
  gap: var(--spacing-02x);
  padding: var(--spacing-02x) var(--spacing-02x) var(--spacing-04x);
  border: var(--border-r-xs) solid var(--color-secondary-subdued);
}

.button-container > svg {
  stroke: var(--color-fg-60);
  pointer-events: none;
}

.button-container > span {
  font-family: sans-serif;
  font-size: var(--spacing-03x);
  font-weight: 600;
  color: var(--color-fg-60);
  pointer-events: none;
}

.highlight-success {
  border: var(--border-r-xs) dashed var(--color-primary-03);
}

.highlight-error {
  border: var(--border-r-xs) dashed var(--color-danger-03);
  color: var(--color-danger-01);
}
