.container {
  display: grid;
  gap: var(--spacing-06x);
  text-align: center;
  padding: var(--spacing-12x) 0;
  background-color: #f9f9fc;
  max-width: 850px;
  margin: 0 auto;
}

.container .columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-12x);
  max-width: 1116px;
}

.card {
  border-radius: var(--border-r-m);
  background-color: var(--color-bg-00);
  width: 408px;
  box-shadow: var(--shadow-01);
}

.cardImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--color-fg-40);
  border-radius: var(--border-r-m);
}

.cardImage {
  width: auto;
  max-width: 100%;
  display: block;
}

.cardContent {
  padding: 16px 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 220px;
}

.cardContent :first-child {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-03x);
}

.cardContent p {
  text-align: left;
}

.cardText {
  text-align: left;
  margin-bottom: 16px;
  height: 112px;
}

.cardButtons {
  display: flex;
  justify-content: center;
  gap: var(--spacing-04x);
}

.cardButtons a {
  text-decoration: none;
}

@media (max-width: 768px) {
  .container {
    max-width: 300px;
  }

  .card {
    width: 300px;
  }

  .container .columns {
    grid-template-columns: 1fr;
  }

  .cardImage {
    height: 200px;
    width: auto;
    object-fit: contain;
  }

  .cardContent {
    padding: 12px 24px;
  }

  .cardText {
    height: auto;
  }

  .cardButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }

  .cardButtons button {
    width: 100%;
  }
}
