.input-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.input-container input {
  text-align: center;
}

.input-shorthand-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-04x);
}

.input-shorthand-container label {
  font-size: var(--font-size-s);
}

.input-size {
  width: 68px;
}

.clickable:hover {
  cursor: pointer;
}

.input-individual-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-08x);
}

.input-individual-container > div {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.input-shorthand-container > div:first-child {
  min-width: var(--spacing-12x);
}

.input-individual {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-02x);
}

.input-container span {
  font-size: var(--font-size-s);
}
