.pricing-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-08x);
  width: 100%;
  max-width: 1200px;
}

.pricing-card-standalone {
  max-width: 350px;
}
