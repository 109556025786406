.dashboard-fonts-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  padding-top: var(--spacing-04x);
}

.fonts-table > tbody > tr > th,
.fonts-table > tbody > tr > td {
  text-align: left;
  font-size: var(--font-size-s);
  line-height: var(--line-height-100);
  padding: var(--spacing-02x) var(--spacing-04x);
  vertical-align: top;
}

.fonts-table .center {
  text-align: center;
}

.font-url {
  width: 400px;
  word-break: break-word;
}

.center-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
