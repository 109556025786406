.project-update-modal-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.form-wrapper button {
  align-self: flex-end;
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.field-wrapper > label {
  font-size: var(--font-size-s);
}
