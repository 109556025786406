.create-custom-block-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: var(--spacing-04x);
}

.create-custom-block-sections-container {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.create-custom-block-preview-container {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-custom-block-sections-list {
  display: flex;
  flex-direction: column;
  color: var(--color-fg-40);
  padding-top: var(--spacing-02x);
  height: 160px;
  overflow-y: auto;
}

.create-custom-block-section {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-02x);
  padding: var(--spacing-02x) var(--spacing-03x);
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease;
}

.create-custom-block-checkbox {
  visibility: hidden;
  margin: 0;
  height: var(--spacing-04x);
  width: var(--spacing-04x);
}

.create-custom-block-checkbox::after {
  content: '';
  display: inline-block;
  width: var(--spacing-04x);
  height: var(--spacing-04x);
  border: var(--border-w-02) solid var(--color-fg-40);
  border-radius: var(--border-r-s);
  background-color: transparent;
  visibility: visible;
  pointer-events: none;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.create-custom-block-checkbox:checked::after {
  border: var(--border-w-02) solid var(--color-info-02);
  background-color: var(--color-info-01);
}

.create-custom-block-section:hover {
  background-color: #8a7ac8;
  color: var(--color-fg-95);
}

.create-custom-block-section:hover .create-custom-block-checkbox::after {
  border: var(--border-w-02) solid var(--color-info-02);
}

.create-custom-block-section[data-checked='true'] {
  background-color: var(--color-info-03);
  color: var(--color-fg-95);
}

.create-custom-block-check {
  position: absolute;
  visibility: hidden;
  transform: translate(30%);
  color: var(--color-bg-00);
  stroke-width: 6px;
}

.create-custom-block-section[data-checked='true'] .create-custom-block-check {
  visibility: visible;
}

.blocks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 1rem;
  padding: var(--spacing-04x);
  height: 300px;
  overflow: auto;
  margin-top: var(--spacing-02x);
}

.custom-block-item-iframe {
  width: 200px;
  height: 250px;
  border: none;
  overflow: hidden;
  background-color: var(--color-bg-30);
}
