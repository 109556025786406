.twoColumnSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-12x);
  background-color: var(--color-success-subdued);
  padding: var(--spacing-16x) 0;
}

.image {
  width: 386px;
  height: auto;
  margin: 0;
}

.columnContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-08x);
  max-width: 446px;
}

.link {
  color: var(--color-fg-60);
  cursor: pointer;
}

.link:visited {
  color: var(--color-fg-60);
}

@media (max-width: 768px) {
  .twoColumnSection {
    flex-direction: column;
    padding: var(--spacing-08x) 24px;
  }

  .image {
    width: 100%;
    max-width: 300px;
  }

  .columnContent {
    width: 100%;
    max-width: 350px;
    align-items: center;
    text-align: center;
  }
}
