.block-iframe-wrapper {
  --project-thumbnail-size: 100%;

  --project-thumbnail-scale: calc(1 / 4);
  --project-frame-scale: calc(1 / var(--project-thumbnail-scale));

  width: calc(var(--project-thumbnail-size) * var(--project-frame-scale));
  height: calc(var(--project-thumbnail-size) * var(--project-frame-scale) * 1);

  /* https://www.w3docs.com/snippets/css/how-to-scale-the-content-of-iframe-element.html */
  transform: scale(var(--project-thumbnail-scale));
  transform-origin: 0 0;
  border: none;
}

.block-iframe-wrapper[data-static='true'] {
  pointer-events: none;
}

.block-iframe-wrapper[data-static='false'] {
  margin-left: 0;
}
