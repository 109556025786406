.not-found-page {
  height: 100%;
}

.not-found-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: var(--spacing-08x) auto;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-02x);
  text-align: center;
}

.not-found-intro {
  font-size: var(--spacing-06x);
  line-height: 1.6;
  font-weight: 600;
  color: var(--cb-50);
}

.not-found-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-02x);
}

.not-found-content img {
  width: 200px;
  height: 200px;
}

.not-found-content button {
  width: 75%;
}

.not-found-message p:first-of-type {
  color: var(--cannoli-primary-01);
  font-weight: 600;
  font-size: 6rem;
  line-height: 1;
}

.not-found-message:nth-child(2) {
  color: var(--cannoli-secondary-02);
  font-weight: 600;
  font-size: var(--spacing-12x);
}

.not-found-help {
  display: flex;
  align-items: center;
}

.not-found-help a {
  color: var(--color-primary-01);
  font-weight: 600;
}
