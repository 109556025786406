.editor-header {
  background: var(--color-bg-30);
  position: initial;
  padding: var(--spacing-02x);
}

.wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--spacing-02x);
}

.options-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-04x);
}

.options-wrapper span {
  margin-left: var(--spacing-08x);
}

.right-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-04x);
}

.left-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--spacing-04x);
}

.editor-header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.editor-menu-dialog {
  text-align: center;
}

.editor-menu-dialog-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-02x);
}

.editor-header-button-link {
  text-decoration: none;
}
