.change-plan-page {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08x);
  padding: var(--spacing-20x);
  align-items: center;
}

.change-plan-title {
  display: flex;
  gap: var(--spacing-04x);
  align-self: flex-start;
}

.change-plan-content {
  width: 100%;
  margin: 0 auto;
}
