.login-with-password-form {
  display: flex;
  flex-flow: column;
  gap: var(--spacing-02x);
}

.login-with-password-form-footer {
  margin-top: var(--spacing-04x);
  display: flex;
  flex-flow: row;
  gap: var(--spacing-02x);
  justify-content: flex-end;
}
