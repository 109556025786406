.navbar-item {
  color: var(--cb-30);
  font-weight: 600;
  padding: var(--spacing-01x) var(--spacing-02x);
  border-bottom: 1px solid transparent;
}

.navbar-item a {
  color: inherit;
  text-decoration: none;
}

.navbar-item.active {
  color: var(--cb-80);
  border-bottom: 1px solid var(--cannoli-primary-01);
}
