.subscription-alert-banner {
  position: absolute;
  background-color: var(--color-danger-subdued);
  display: flex;
  align-items: center;
  color: var(--color-danger-03);
  padding: var(--spacing-01x) var(--spacing-04x);
  font-size: var(--font-size-s);
  max-width: 530px;
  left: calc(50% - 240px / 2);
  top: var(--spacing-05x);
  border-radius: var(--border-r-s);
}

.subscription-alert-banner-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: var(--spacing-02x);
}
