.footer-public {
  min-height: var(--footer-height);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-20x);
  background-color: var(--cb-80);
  padding: var(--spacing-20x);
  color: var(--cb-10);
}

.footer-main-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.company-logos {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08x);
  width: 350px;
}

.footer-cannoli-logo {
  max-width: 240px;
}

.company-logo {
  width: 100%;
  max-width: 150px;
}

.company-information {
  text-align: end;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08x);
}

.company-information a {
  color: var(--color-primary-01);
}

.footer-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.footer-section > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: var(--spacing-06x);
}

.footer-section h3 {
  color: var(--cb-10);
  margin-bottom: var(--spacing-04x);
}

.footer-section a {
  font-weight: 400;
}

.footer-section svg {
  width: var(--spacing-06x);
  height: var(--spacing-06x);
}

.footer-legals {
  font-size: var(--font-size-s);
}

.footer-badges-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-04x);
}

@media screen and (max-width: 768px) {
  .footer-public {
    padding: var(--spacing-08x);
  }

  .footer-main-content {
    flex-direction: column;
    gap: var(--spacing-08x);
  }

  .company-information,
  .footer-section {
    align-items: flex-start;
    text-align: start;
  }
}

@media screen and (max-width: 480px) {
  .company-logos {
    width: 100%;
  }
}
