/* Estilos base (para desktop) */

.services-section-wrapper {
  display: flex;
  gap: var(--spacing-08x);
  padding: var(--spacing-12x) 0;
  justify-content: center;
}

.services-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-06x);
  max-width: 486px;
  color: var(--color-fg-80);
}

.services-asset-container {
  max-width: 322px;
}

@media (max-width: 768px) {
  .services-section-wrapper {
    flex-direction: column-reverse;
    padding: 0;
  }

  .services-description {
    align-items: center;
    max-width: 100%;
    text-align: center;
    padding: var(--spacing-03x);
  }

  .services-asset-container {
    max-width: 100%;
    margin: 0 auto;
  }
}
