.dashboard-page {
  display: flex;
  height: 100%;
  transition: all 0.2s ease;
}

.dashboard-content {
  --bg-color: var(--cb-10);
  --font-color: var(--color-fg-80);

  display: flex;
  flex-flow: column;
  gap: var(--spacing-04x);
  background: var(--bg-color);
  color: var(--font-color);
  transition: all 0.2s ease;
  margin-left: 240px;
}

.dashboard-page.collapsed .dashboard-content {
  margin-left: var(--spacing-20x);
}

.dashboard-main {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-04x) var(--spacing-12x);
  gap: var(--spacing-04x);
}

.dashboard-main > div {
  flex-grow: 1;
}

.dashboard-footer {
  margin-left: 240px;
}

.dashboard-page.collapsed .dashboard-footer {
  margin-left: var(--spacing-20x);
}
