.highlight-body > div {
  border: solid 2px var(--color-info-01);
}

.highlight-body > span {
  display: inline-block;
  font-family: var(--font-family-inter);
  font-size: var(--spacing-03x);
  font-weight: var(--font-weight-semi-bold);
  padding: var(--spacing-02x);
  white-space: nowrap;
  background-color: var(--color-info-01);
  color: var(--color-bg-00);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  pointer-events: none;
}
