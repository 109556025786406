.cannoli-workflow-section {
  background: var(--color-secondary-subdued);
  display: flex;
  flex-direction: column;
  padding: var(--spacing-16x) 0;
  align-items: center;
}

.cannoli-workflow-container {
  max-width: 1100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-08x);
}

.cannoli-workflow-container > p {
  text-align: center;
}

.workflow-grid-desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-08x);
  align-self: stretch;
}

.workflow-grid-mobile {
  display: none;
  width: 100%;
}

@media (max-width: 1200px) {
  .cannoli-workflow-section {
    padding: var(--spacing-08x) var(--spacing-06x);
  }
}

@media (max-width: 768px) {
  .workflow-grid-mobile {
    display: flex;
    flex-direction: column;
  }

  .workflow-grid-desktop {
    display: none;
  }
}
