.add-seats-container {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
}

.add-seats-input input[type='number']::-webkit-inner-spin-button,
.add-seats-input input[type='number']::-webkit-outer-spin-button {
  opacity: var(--opacity-10);
}

.add-seats-input input {
  width: var(--spacing-12x);
  height: var(--spacing-08x);
  background-color: var(--cb-10);
  border-color: var(--cb-20);
  display: inline;
}

.add-seats-legend {
  font-size: var(--font-size-s);
}

.add-seats-due-today {
  display: flex;
}
