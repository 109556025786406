.login-with-password-page {
  background-color: var(--color-fg-90);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  gap: 160px;
}

.login-with-password-page-form {
  flex: 0 1 50%;
  max-width: 600px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: var(--spacing-12x);
}
