.file-row:hover {
  background-color: var(--color-bg-20);
  font-weight: bold;
  cursor: pointer;
}

.file-row > td {
  padding: var(--spacing-02x);
}

.file-name-cell {
  width: 450px;
}

.file-row a {
  text-decoration: none;
  width: 100%;
}

.file-row .file-preview-cell {
  height: var(--spacing-20x);
  width: var(--spacing-20x);
  padding: var(--spacing-04x);
}

.file-preview-cell img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.file-created-at-cell,
.file-date-updated-cell {
  width: 250px;
}

.row-placeholder-image {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: var(--spacing-06x);
}

.file-row[data-disabled='true'] {
  opacity: var(--opacity-04);
  pointer-events: none;
  cursor: not-allowed;
}
