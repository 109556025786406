:root {
  font-family: var(--font-family-inter);
  line-height: var(--line-height-p);
  color: var(--color-fg-80);
  background: var(--cb-10);

  --cannoli-primary-01: #504092;
  --cannoli-secondary-02: #6857ac;

  --cb-95: #141518;
  --cb-90: #08021b;
  --cb-80: #080029;
  --cb-70: #262639;
  --cb-60: #373745;
  --cb-50: #515166;
  --cb-40: #727297;
  --cb-30: #c6c6df;
  --cb-20: #dedeec;
  --cb-10: #f9f9fc;

  --purple-01: #8f7dff;
  --purple-02: #09041b;
  --purple-03: #473b75;

  --extra-color-01: #97add0;
  --font-02: 'Domine', serif;

  --gradient-01: linear-gradient(
    0deg,
    rgba(62 45 135 / 0%) -17.5%,
    var(--purple-01) 100%
  );
  --gradient-02: linear-gradient(
    360deg,
    var(--purple-02) 0%,
    rgba(27 12 91 / 93.6229%) 13.9%,
    rgba(160 137 255 / 0%) 198.75%
  );

  /* Added new tokens here because they are needed for toggle, and an update on the design-tokens would affect the whole app */
  --spacing-01x: 4px;
  --spacing-02x: 8px;
  --spacing-03x: 12px;
  --spacing-04x: 16px;
  --spacing-05x: 20px;
  --spacing-06x: 24px;
  --spacing-07x: 28px;
  --spacing-08x: 32px;
  --spacing-09x: 36px;
  --spacing-10x: 40px;
  --spacing-11x: 44px;
  --spacing-12x: 48px;
  --spacing-14x: 56px;
  --spacing-16x: 64px;
  --spacing-18x: 72px;
  --spacing-20x: 80px;

  --size-01: 0.25rem;
  --size-02: 0.5rem;
  --size-03: 0.75rem;
  --size-04: 1rem;
  --size-05: 1.25rem;
  --size-06: 1.5rem;
  --size-07: 1.75rem;
  --size-08: 2rem;
  --size-09: 2.25rem;
  --size-10: 2.5rem;
  --size-11: 2.75rem;
  --size-12: 3rem;

  --color-fg-95: #010002;
  --color-fg-90: #0d032c;
  --color-fg-80: #080029;
  --color-fg-70: #262639;
  --color-fg-60: #323245;
  --color-fg-50: #515166;
  --color-fg-40: #727297;
  --color-bg-30: #9a9aae;
  --color-bg-20: #d5d5e2;
  --color-bg-10: #ebebf0;
  --color-bg-00: #fff;

  --color-extra-01: #292256;

  --img-bg-transparent: url('../images/svg/bg-transparent.svg');
}

#root {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}

a {
  color: var(--color-bg-00);
}

hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color: var(--color-bg-20);
}

h4 {
  font-weight: var(--font-weight-semi-bold);
}

html {
  scroll-behavior: smooth;
}

body {
  overflow: overlay;
}

.wrapper {
  width: 100%;
  padding: var(--spacing-02x) var(--spacing-12x);
}

[data-reach-menu-popover] {
  z-index: 100;
}

.-spin {
  animation: forno-spin 1s linear infinite;
}

[data-reach-dialog-overlay] {
  z-index: 100;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: var(--border-r-s);
}

::-webkit-scrollbar-thumb {
  background: var(--color-fg-40);
  border-radius: var(--border-r-s);
}

@media (max-width: 1024px) {
  .wrapper {
    padding: var(--spacing-02x) var(--spacing-08x);
  }
}

#gist-app {
  position: absolute;
}
