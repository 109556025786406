.container {
  display: flex;
  padding: var(--spacing-02x);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-02x);
  color: var(--color-danger-03);
  font-family: var(--font-family-inter);
  font-size: var(--spacing-03x);
  background-color: var(--color-danger-subdued);
  pointer-events: none;
}
