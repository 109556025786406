.dashboard-templates {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  flex-grow: 1;
  height: 0;
}

.dashboard-templates-actions {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  gap: var(--spacing-04x);
  color: var(--color-fg-70);
  font-size: var(--font-size-s);
}

.dashboard-templates-right-actions {
  display: flex;
  gap: var(--spacing-04x);
}

.dashboard-templates-action-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-02x);
}

.dashboard-templates-view-buttons button {
  border: none;
}

.dashboard-templates-container {
  flex-grow: 1;
  overflow-y: auto;
}
