.no-subscriptions-alert {
  background-color: var(--color-warning-01);
  color: var(--color-neutral-01);
  display: flex;
  padding: var(--spacing-01x) var(--spacing-02x);
  font-size: var(--font-size-s);
  align-items: center;
}

.no-subscriptions-alert-content {
  display: flex;
  align-items: center;
  gap: var(--spacing-02x);
  width: 100%;
  justify-content: center;
}
