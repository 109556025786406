.editor-header-contents {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
  gap: var(--spacing-02x);
}

.editor-header-actions {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-04x);
}

.editor-header-name-wrapper {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
}

.export-button-section {
  display: flex;
  gap: var(--spacing-02x);
}

.esp-error-message a {
  color: var(--color-primary-01);
}

.editor-header-title-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-08x);
  width: 100%;
}

.export-option-title {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: var(--spacing-01x);
}

.export-option-title svg {
  cursor: pointer;
}
