.table-of-contents {
  margin-top: var(--spacing-04x);
  width: 320px;
}

.table-of-contents,
.table-of-contents a {
  color: var(--fg-60);
  font-weight: var(--font-weight-semi-bold);
  text-decoration: none;
}

.table-of-contents li,
.table-of-contents ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}
