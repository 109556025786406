.billing-information {
  width: 100%;
}

.billing-information table {
  width: 100%;
  border-collapse: collapse;
}

.billing-information td {
  padding: var(--spacing-02x);
}

.billing-information th {
  padding: 0;
}

.billing-information tr:nth-child(odd) {
  background-color: var(--cb-10);
}

.title-column-head {
  width: 100%;
}

.title-cell {
  font-weight: 600;
}

.status-cell {
  text-align: end;
}
