.tag {
  border-radius: var(--border-r-xl);
  background-color: var(--cb-20);
  font-size: var(--font-size-s);
  margin: auto 0;
  padding: var(--spacing-01x) var(--spacing-02x);
  border: 1px solid var(--cb-30);
  color: var(--cb-70);
  display: flex;
  gap: var(--spacing-01x);
  width: max-content;
  align-items: center;
}

.tag[data-status='success'] {
  --success-color: rgba(27 192 122 / 10%);

  background-color: var(--success-color);
  color: var(--color-success-02);
  border: 1px solid var(--color-success-02);
}

.tag[data-status='warning'] {
  --warning-color: rgba(255 212 109 / 10%);

  background-color: var(--warning-color);
  color: var(--color-warning-02);
  border: 1px solid var(--color-warning-02);
}

.tag[data-status='danger'] {
  --danger-color: rgba(252 111 106 / 10%);

  background-color: var(--danger-color);
  color: var(--color-danger-02);
  border: 1px solid var(--color-danger-02);
}
