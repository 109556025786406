.features-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-08x) 0 var(--spacing-16x) 0;
}

.features-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-08x);
  max-width: 1100px;
}

.features-grid-desktop {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--spacing-16x);
}

.features-grid-mobile {
  display: none;
}

.features-section div {
  width: 100%;
}

@media (max-width: 1000px) {
  .features-grid-desktop {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features-grid-mobile {
    display: flex;
  }

  .features-grid-desktop {
    display: none;
  }
}
