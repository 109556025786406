.header-public {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--cb-80);
  padding: var(--spacing-02x) var(--spacing-12x);
}

.header-menu-navigation {
  display: flex;
  gap: var(--spacing-04x);
}

.header-menu-navigation a {
  align-self: center;
}

.header-menu-mobile {
  display: none;
}

.header-menu-desktop {
  list-style: none;
  display: flex;
  gap: var(--spacing-06x);
  align-items: center;
  margin: 0;
  padding: var(--spacing-02x) 0;
}

.header-menu-desktop-item a {
  text-decoration: none;
}

.header-menu-mobile-items {
  border-radius: var(--border-r-m);
  color: var(--cb-80);
  padding: var(--spacing-02x);
}

.header-menu-mobile-item[data-reach-menu-item] {
  padding: var(--spacing-02x);
}

.header-menu-mobile-item[data-reach-menu-item]:last-of-type {
  margin-top: var(--spacing-02x);
}

.header-public-logo-wrapper {
  height: fit-content;
  display: flex;
  align-content: center;
}

.header-cannoli-logo {
  min-height: var(--spacing-10x);
  align-self: center;
  justify-self: center;
  max-width: 192px;
}

.accordion {
  position: relative;
}

.accordion-header {
  background-color: transparent;
  border: none;
  color: var(--color-bg-00);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-02x);
  cursor: pointer;
  padding: 0;
}

.accordion-body {
  background-color: var(--cb-80);
  border-radius: 0 0 var(--border-r-m) var(--border-r-m);
  display: flex;
  flex-direction: column;
}

.accordion-body-floating {
  background-color: var(--cb-80);
  border-radius: 0 0 var(--border-r-m) var(--border-r-m);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: var(--spacing-11x);
  left: -18px;
}

.accordion-body-children {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  padding: var(--spacing-02x);
}

.accordion-body-children a {
  text-decoration: none;
  align-self: flex-start;
  padding: var(--spacing-02x) var(--spacing-04x);
  width: 100%;
}

.menuAccordion {
  position: relative;
}

.menu-accordion-header {
  background-color: transparent;
  border: none;
  color: var(--color-bg-00);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-02x);
  cursor: pointer;
}

.menu-accordion-body {
  position: absolute;
  top: var(--spacing-11x);
  right: 0;
  background-color: var(--cb-80);
  border-radius: 0 0 var(--border-r-m) var(--border-r-m);
  z-index: 90;
}

.menu-accordion-body-children {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  padding: var(--spacing-02x);
}

.menu-accordion-body a {
  text-decoration: none;
  align-self: flex-start;
  width: 100%;
}

.header-menu-navigation a:hover,
.header-menu-desktop-item a:hover {
  color: var(--color-bg-30);
  transition: color 0.3s ease;
}

.accordion-header:hover,
.menu-accordion-header:hover {
  color: var(--color-bg-30);
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .header-public {
    padding: var(--spacing-04x);
  }

  .header-menu-mobile {
    display: block;
  }

  .header-menu-desktop {
    display: none;
  }

  .login-button {
    margin-top: var(--spacing-04x);
  }
}
