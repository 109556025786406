.help-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: var(--color-success-subdued);
}

.help-section-container {
  display: flex;
  padding: var(--spacing-16x) 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-08x);
  align-self: stretch;
  max-width: 1100px;
}

.help-section-container h2 {
  text-align: center;
}

@media (max-width: 768px) {
  .help-section-container {
    padding: var(--spacing-16x) var(--spacing-06x);
  }
}
