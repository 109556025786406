.add-seats-legend {
  font-size: var(--font-size-s);
  padding: 0 var(--spacing-02x);
}

.button-link {
  border: none;
  background-color: transparent;
  padding: 0;
  color: var(--color-primary-01);
}

.button-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
