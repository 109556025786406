.integration-remove-modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.integration-remove-modal-content img,
.integration-remove-modal-content h3 {
  align-self: center;
}

.integration-remove-modal-content-list {
  margin: 0;
  padding: 0;
}

.integration-remove-modal-content-list li {
  list-style: none;
}

.integration-image-container {
  margin: auto;
  display: flex;
  align-items: center;
  gap: var(--spacing-04x);
}

.modal-icon {
  font-size: var(--spacing-06x);
  color: var(--color-danger-01);
}

.integration-list-item {
  display: flex;
  align-items: center;
  gap: var(--spacing-02x);
}

.integration-list-icon {
  color: var(--color-primary-01);
}

.logo-image {
  max-height: var(--size-12);
  max-width: var(--size-12);
}
