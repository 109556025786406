.input-textarea-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01x);
}

.input-textarea-container label {
  font-size: var(--font-size-s);
}

.input-textarea textarea {
  resize: vertical;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-160);
  border: var(--border-w-01) solid var(--color-fg-40);
  background-color: transparent;
  outline: none;
  width: 100%;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  color: var(--color-fg-60);
  border-radius: var(--border-r-s);
  padding: var(--spacing-02x);
}

.input-textarea textarea::placeholder {
  color: var(--color-fg-40);
}

.input-textarea textarea:hover {
  border-color: var(--color-info-02);
}

.input-textarea textarea:focus {
  border-color: var(--color-info-01);
}
