.editor-save-indicator {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-02x);
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  transition: 0.2s ease all;
}

.indicator[data-status='fetching'] {
  background-color: var(--color-warning-02);
}

.indicator[data-status='success'] {
  background-color: var(--color-success-02);
}
