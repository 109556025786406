.file-create-form {
  color: var(--color-fg-60);
  font-size: var(--font-size-m);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--spacing-08x);
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.field-wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  gap: var(--spacing-01x);
}

.frame-selection-dropdowns {
  display: flex;
  gap: var(--spacing-04x);
}

.frame-selection-dropdowns > div {
  width: 50%;
}

.frame-node-preview-container {
  margin: auto;
  background-color: var(--cb-30);
  border-radius: var(--border-r-xl);
  width: 100%;
  padding: var(--spacing-04x) var(--spacing-20x);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 400px;
}

.frame-node-preview-container > div {
  overflow: auto;
  max-height: 100%;
}

.field-wrapper > div,
.field-wrapper > div > div {
  width: 100%;
}

.field {
  width: 100%;
}

.field-wrapper input {
  width: 100%;
}

.file-create-form-footer {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: var(--spacing-04x);
  padding-top: var(--spacing-04x);
  border-top: 1px solid var(--color-bg-20);
}

.preview-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.preview-placeholder-icon {
  color: var(--color-bg-30);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
