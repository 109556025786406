.resources-section {
  background-color: var(--cb-10);
  padding: var(--spacing-16x) var(--spacing-08x);
}

.resources-section .grid {
  width: 100%;
  max-width: 1280px;
  display: grid;
  grid-gap: var(--spacing-08x);
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
}

.resources-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-08x);
}

.resources-container h2,
.resources-container > p {
  text-align: center;
}

@media (max-width: 768px) {
  .resources-section {
    padding: var(--spacing-06x);
  }
}
