.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-06x);
  text-align: center;
  padding: var(--spacing-12x) 0;
}

.section svg {
  color: var(--color-fg-70);
}

.description {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  align-items: center;
  text-align: center;
}

.description a {
  color: inherit;
  text-decoration: underline;
}

.styledAnchor a {
  color: inherit;
  text-decoration: none;
}

.description a:visited {
  color: inherit;
}
