.preview-wrapper {
  overflow-y: auto;
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
}

.imageWrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex: 1;
}

.image {
  width: 100%;
  height: auto;
  min-height: 100%;
  max-width: none;
  display: block;
}

.handle {
  border-radius: 50%;
  background-color: var(--color-bg-00);
  border: 1px solid var(--color-danger-01);
  width: 50px;
  height: 50px;
}

.handle-before,
.handle-after {
  height: 100%;
  background-color: var(--color-bg-00);
  width: 0.25rem;
}
