.billing-plan {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  padding: var(--spacing-04x);
  padding-top: var(--spacing-02x);
  border-radius: var(--border-r-m);
  background-color: var(--cb-10);
}

.billing-plan button {
  align-self: flex-end;
}

.billing-plan-content {
  display: flex;
  gap: var(--spacing-20x);
  justify-content: space-between;
}

.billing-plan-current,
.billing-features {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.billing-plan-current h2 {
  display: flex;
  align-items: center;
  gap: var(--spacing-02x);
}

.billing-plan-current-title {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
  justify-content: flex-start;
}

.billing-features-list {
  display: flex;
  gap: var(--spacing-06x);
  padding-left: var(--spacing-04x);
}

.billing-features-list p {
  font-size: var(--font-size-s);
}

.billing-plan-current-info {
  width: max-content;
  font-size: var(--font-size-s);
}

.billing-plan-trial {
  display: flex;
  flex-direction: column;
  align-items: center;
}
