.compare-slider-handle {
  width: 40px;
  height: 40px;
  border: 2px solid var(--color-bg-00);
  border-radius: 50%;
  transition: all 0.2s ease;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 20%);
  backdrop-filter: blur(7px);
  box-shadow: rgb(0 0 0 / 35%) 0 0 7px;
  position: sticky;
  top: 10%;
}

.compare-slider-handle:hover {
  border-color: var(--color-success-02);
}

.compare-slider-handle[data-focused='true'] {
  border-color: var(--color-success-01);
}

.compare-slider-handle svg {
  width: 60%;
  height: 60%;
}
