.project-card-menu-list[data-reach-menu-list] {
  --border-color: var(--color-bg-30);
  --bg-color: var(--color-bg-00);

  background-color: var(--bg-color);
  outline: none;
  padding: var(--spacing-02x) 0;
  color: inherit;
  border-radius: var(--border-r-s);
  box-shadow: 4px 4px 4px rgba(0 0 0 / 5%);
}

.project-card-menu-item[data-reach-menu-item] {
  color: var(--color-fg-70);
  border-radius: var(--border-r-sharp);
  font-weight: 400;
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
  transition: all ease 0.2s;
}

.project-card-menu-item[data-reach-menu-item]:hover {
  color: var(--color-fg-80);
  background-color: var(--color-bg-30);
}

.project-card-menu-item:hover {
  --bg-color: var(--color-bg-00);

  background-color: var(--bg-color);
  color: var(--color-tertiary-02);
}

.project-card-menu-button svg {
  stroke-width: 1px;
}
