.file-new-button [data-reach-menu-button] {
  padding: var(--spacing-01x);
  font-size: var(--spacing-04x);
}

.file-new-button-list[data-reach-menu-list] {
  --border-color: var(--color-bg-30);
  --bg-color: var(--color-bg-00);

  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
  outline: none;
  padding: var(--spacing-02x) 0;
  color: inherit;
  border-radius: var(--border-r-s);
  box-shadow: 4px 4px 4px rgba(0 0 0 / 5%);
}

.file-new-button-item[data-reach-menu-item] {
  color: var(--color-fg-70);
  border-radius: var(--border-r-sharp);
  font-weight: 400;
  display: flex;
  gap: var(--spacing-02x);
}

.file-new-button-item[data-reach-menu-item]:hover {
  color: var(--color-fg-80);
  background-color: var(--color-bg-30);
}

.file-new-button-item:hover {
  --bg-color: var(--color-bg-00);

  background-color: var(--bg-color);
  border: 1px solid var(--bg-color);
  color: var(--color-tertiary-02);
}
