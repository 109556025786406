.dashboard-sidebar {
  --bg-color: var(--cb-80);

  display: flex;
  flex-direction: column;
  font-size: var(--font-size-m);
  padding: var(--spacing-04x) var(--spacing-02x);
  background-color: var(--bg-color);
  width: 240px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  transition: all 0.2s ease;
  color: var(--cb-10);
}

.dashboard-sidebar ::-webkit-scrollbar-thumb {
  background: var(--cb-40);
}

.dashboard-sidebar-header {
  display: flex;
  height: var(--spacing-06x);
  justify-content: space-between;
  padding: 0;
}

.dashboard-sidebar-top {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.teams-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  max-height: 60vh;
  padding-right: var(--spacing-01x);
  overflow-y: hidden;
}

.teams-container:hover {
  overflow-y: auto;
}

.sidebar-body {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01x);
}

.divider {
  height: 2px;
  border: none;
  background-color: #26263a;
  margin: var(--spacing-04x) 0;
}

.dashboard-sidebar.collapsed {
  width: var(--spacing-20x);
  padding: var(--spacing-02x);
}

.accordion-header {
  background-color: transparent;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.accordion-header-svg {
  position: absolute;
  color: var(--cb-10);
  right: var(--spacing-02x);
  top: var(--spacing-02x);
  pointer-events: none;
}

.accordion-body {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: var(--spacing-01x);
  margin-left: var(--spacing-06x);
}

.dashboard-sidebar-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
}

.dashboard-sidebar-body-content {
  height: 100%;
}
