.dashboard-personal {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  height: 100%;
}

.dashboard-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-04x);
}

.dashboard-banner {
  margin: -1rem -3rem var(--spacing-02x);
}
