.code-editor-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1 100px;

  :global(.ace-editor) {
    font-size: 1rem;
    border-radius: var(--border-r-sharp);
  }
}

.code-editor-wrapper ::-webkit-scrollbar {
  width: var(--spacing-01x);
  background: var(--color-fg-95);
}

.code-editor-wrapper ::-webkit-scrollbar-track {
  border-radius: var(--border-r-s);
}

.code-editor-wrapper ::-webkit-scrollbar-thumb {
  background: var(--color-fg-60);
  border-radius: var(--border-r-s);
  height: 182px;
}
