.connect-page {
  background-color: var(--cb-10);
  color: var(--color-fg-80);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: var(--spacing-08x);
}

.connect-page > section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-08x);
}

.connect-page-container {
  max-width: 800px;
}

.paired-page-container {
  max-width: 600px;
}

.connect-page h1 {
  font-family: var(--font-02);
  font-size: 2rem;
}

.connect-page a,
.connect-page span {
  color: var(--color-primary-01);
}

.connect-page a {
  text-decoration: none;
}

.connect-page div {
  font-size: var(--font-size-m);
}
