.checkout-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f9f8fb;
}

.checkout-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-06x);
  padding: var(--spacing-12x) 0;
  width: 100%;
  max-width: 1052px;
  margin: 0 auto;
}

.checkout-container-title {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
}

.checkout-section {
  padding: var(--spacing-12x) 0;
  max-width: 854px;
  margin: 0 auto;
}

.checkout-section header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01x);
}

.what-you-get-section header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01x);
}

.what-you-get-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08x);
  max-width: 854px;
  margin: 0 auto;
  padding-bottom: var(--spacing-12x);
}

.what-you-get-container {
  display: flex;
  gap: var(--spacing-08x);
  justify-content: space-between;
  max-width: 854px;
  margin: 0 auto;
}

.what-you-get-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-06x);
  text-align: center;
  background-color: var(--color-bg-00);
  border-radius: var(--border-r-m);
  padding: var(--spacing-03x) 6px;
  width: 192px;
  height: 150px;
}

.what-you-get-item p {
  line-height: 150%;
  font-size: 20px;
}

.what-you-get-item svg {
  color: var(--color-success-01);
}

.divider {
  width: 100%;
  object-fit: cover;
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .checkout-container {
    padding: var(--spacing-12x) var(--spacing-04x);
  }

  .checkout-header {
    margin: 0;
    padding: 0 0 var(--spacing-08x) 0;
  }

  .checkout-section {
    padding: var(--spacing-12x) var(--spacing-04x);
  }

  .what-you-get-section {
    padding: var(--spacing-12x) var(--spacing-04x);
  }

  .what-you-get-container {
    flex-direction: column;
    gap: var(--spacing-08x);
  }
}
