.editor-code-container {
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.editor .editor-code-editor-content {
  flex: 1 1 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.only-preview .editor-code-editor-content {
  max-width: 800px;
  width: 100%;
  margin: auto;
  grid-template-columns: 1fr;
}
