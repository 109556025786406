.form {
  width: 100%;
  display: flex;
  justify-content: center;
}

.input-container {
  display: flex;
  width: 100%;
  max-width: 512px;
  margin: 0 var(--spacing-04x);
}

.input {
  width: 60%;
  padding: var(--spacing-02x) var(--spacing-04x);
  border: 1px solid var(--cb-70);
  border-radius: var(--spacing-02x) 0 0 var(--spacing-02x);
}

.input-button[data-status='normal'] {
  border-radius: 0 var(--spacing-02x) var(--spacing-02x) 0 !important;
  width: 50%;
}

/* responsive */

@media (max-width: 768px) {
  .input {
    width: 100%;
    border-radius: var(--spacing-02x) var(--spacing-02x) var(--spacing-02x)
      var(--spacing-02x);
  }

  .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 512px;
    margin: 0 var(--spacing-04x);
    gap: var(--spacing-08x);
  }

  .input-button[data-status='normal'] {
    width: 100% !important;
    border-radius: var(--spacing-02x) var(--spacing-02x) var(--spacing-02x)
      var(--spacing-02x) !important;
  }
}
