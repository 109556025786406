.block-card {
  aspect-ratio: 7/10;
}

.block-card[data-disabled='true'] {
  opacity: var(--opacity-04);
  pointer-events: none;
  cursor: not-allowed;
}

.block-card > a > section {
  display: none;
}

.block-card > a > footer div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  justify-content: space-between;
  height: 100%;
}

.block-card > a > footer div:first-child {
  align-self: flex-start;
}

.block-card > a > footer div:not(:first-child) {
  align-self: flex-end;
}

.block-card > a > footer > div > h3 {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semi-bold);
  color: var(--card-fg-color-normal);
}
