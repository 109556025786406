.files-table table {
  width: 100%;
  overflow-wrap: break-word;
  font-size: var(--font-size-s);
}

.files-table th {
  padding: var(--spacing-02x);
  text-align: left;
  border-bottom: 1px solid var(--font-color);
}
