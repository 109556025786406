.ace-monokai-cannoli .ace_gutter {
  background: var(--cb-95);
  color: #8f908a;
}

.ace-monokai-cannoli .ace_print-margin {
  width: 1px;
  background: #555651;
}

.ace-monokai-cannoli {
  background-color: var(--cb-95);
  color: #f8f8f2;
}

.ace-monokai-cannoli .ace_cursor {
  color: #f8f8f0;
}

.ace-monokai-cannoli .ace_marker-layer .ace_selection {
  background: #49483e;
}

.ace-monokai-cannoli.ace_multiselect .ace_selection.ace_start {
  box-shadow: 0 0 3px 0 #272822;
}

.ace-monokai-cannoli .ace_marker-layer .ace_step {
  background: rgb(102 82 0);
}

.ace-monokai-cannoli .ace_marker-layer .ace_bracket {
  margin: -1px 0 0 -1px;
  border: 1px solid #49483e;
}

.ace-monokai-cannoli .ace_marker-layer .ace_active-line {
  background: #202020;
}

.ace-monokai-cannoli .ace_gutter-active-line {
  background-color: #272727;
}

.ace-monokai-cannoli .ace_marker-layer .ace_selected-word {
  border: 1px solid #49483e;
}

.ace-monokai-cannoli .ace_invisible {
  color: #52524d;
}

.ace-monokai-cannoli .ace_entity.ace_name.ace_tag,
.ace-monokai-cannoli .ace_keyword,
.ace-monokai-cannoli .ace_meta.ace_tag,
.ace-monokai-cannoli .ace_storage {
  color: #f92672;
}

.ace-monokai-cannoli .ace_punctuation,
.ace-monokai-cannoli .ace_punctuation.ace_tag {
  color: #ffd760;
}

.ace-monokai-cannoli .ace_constant.ace_character,
.ace-monokai-cannoli .ace_constant.ace_language,
.ace-monokai-cannoli .ace_constant.ace_numeric,
.ace-monokai-cannoli .ace_constant.ace_other {
  color: #ae81ff;
}

.ace-monokai-cannoli .ace_invalid {
  color: #f8f8f0;
  background-color: #f92672;
}

.ace-monokai-cannoli .ace_invalid.ace_deprecated {
  color: #f8f8f0;
  background-color: #ae81ff;
}

.ace-monokai-cannoli .ace_support.ace_constant,
.ace-monokai-cannoli .ace_support.ace_function {
  color: #66d9ef;
}

.ace-monokai-cannoli .ace_fold {
  background-color: #a6e22e;
  border-color: #f8f8f2;
}

.ace-monokai-cannoli .ace_storage.ace_type,
.ace-monokai-cannoli .ace_support.ace_class,
.ace-monokai-cannoli .ace_support.ace_type {
  font-style: italic;
  color: #66d9ef;
}

.ace-monokai-cannoli .ace_entity.ace_name.ace_function,
.ace-monokai-cannoli .ace_entity.ace_other,
.ace-monokai-cannoli .ace_entity.ace_other.ace_attribute-name,
.ace-monokai-cannoli .ace_variable {
  color: #18f509;
}

.ace-monokai-cannoli .ace_variable.ace_parameter {
  font-style: italic;
  color: #fd971f;
}

.ace-monokai-cannoli .ace_string {
  color: #ffd760;
}

.ace-monokai-cannoli .ace_comment {
  color: #2cd7d1;
}
