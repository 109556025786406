.stack-item {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.stack-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.stack-item-header > svg:hover {
  cursor: pointer;
}
