.file-editor-debug-wrapper {
  display: flex;
  flex-flow: column;
  height: 100vh;
  overflow-y: auto;
}

.file-editor-debug-wrapper > header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
}

.file-editor-debug-editor {
  flex: 1;
}

.file-editor-debug-code {
  display: flex;
  flex-flow: row;
  flex: 1;
}

.file-editor-debug-code > * {
  flex: 1 1 50%;
}
