.form-container {
  display: flex;
  gap: var(--spacing-16x);
  padding-top: var(--spacing-08x);
}

.form-inputs {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-03x);
  max-width: 460px;
}

.form-label {
  color: var(--color-fg-40);
}

.form-paired-inputs {
  display: flex;
  gap: var(--spacing-04x);
}

.form-paired-inputs div {
  width: 100%;
}

.error-message {
  color: var(--color-danger-01);
}

.number {
  width: 30%;
}

.checked span,
.checked span a {
  color: var(--color-fg-80);
}

.description-label {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01x);
}

.bold p {
  font-weight: 600;
}

.terms-and-signup-check {
  display: flex;
  align-items: center;
  gap: var(--spacing-01x);
}

.terms-and-signup-check input[type='checkbox'] {
  appearance: auto;
}

.terms-and-signup-check label {
  cursor: pointer;
}

.form-input-field {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.form-input-field textarea {
  min-height: 90px;
  height: 90px;
}

@media (max-width: 768px) {
  .form-container {
    flex-direction: column;
    gap: var(--spacing-08x);
  }

  .form-paired-inputs div {
    width: 100%;
  }
}
