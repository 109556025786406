.blocks-modal-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 1rem;
  width: 100%;
  gap: var(--spacing-04x);
  height: 100%;
  place-items: center;
}
