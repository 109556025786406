.template-form {
  color: var(--color-fg-60);
  font-size: var(--font-size-m);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--spacing-08x);
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  height: 100%;
}

.field-wrapper {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
  gap: var(--spacing-01x);
}

.template-node-preview-container {
  margin: auto;
  background-color: var(--cb-30);
  border-radius: var(--border-r-xl);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}

.field-wrapper > div,
.field-wrapper > div > div {
  width: 100%;
}

.field {
  width: 100%;
}

.field-wrapper input {
  width: 100%;
}

.template-form-footer {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: var(--spacing-04x);
  padding-top: var(--spacing-04x);
}

.preview-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  width: 600px;
}

.templates-iframe {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

.preview-placeholder-icon {
  color: var(--color-bg-30);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.templates-grid-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  height: 100%;
}

.templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 300px));
  gap: var(--spacing-04x);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40em;
  overflow-y: auto;
}

.template-card-wrapper {
  cursor: pointer;
  border-radius: var(--border-r-m);
}
