.search-fonts {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.dropdown-content {
  position: absolute;
  min-width: 260px;
  background-color: var(--color-bg-00);
  border: var(--border-w-01) solid var(--color-bg-20);
  border-radius: var(--border-r-m);
  box-shadow: 0 var(--spacing-01x) var(--spacing-03x) rgba(2 0 8 / 15%);
  padding: var(--spacing-02x) var(--spacing-01x);
  z-index: 1;
}

.dropdown-options-container {
  max-height: 240px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.dropdown-option {
  color: var(--color-fg-80);
  background-color: transparent;
  border: 0;
  padding: var(--spacing-02x);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semi-bold);
  text-align: left;
}

.dropdown-option:hover {
  background-color: var(--color-bg-10);
  cursor: pointer;
}

.add-button {
  width: 100%;
  margin-top: var(--spacing-01x);
}
