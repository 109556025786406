.team-members-table {
  width: 100%;
  font-size: var(--font-size-s);
}

.team-members-table th {
  color: var(--color-bg-30);
  font-weight: 600;
  text-align: start;
  padding: 0;
  padding-bottom: var(--spacing-06x);
}

.user-name-cell {
  min-width: 280px;
}

.user-name-wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-02x);
}

.user-email-cell {
  width: 100%;
  margin: 0 auto;
}

.permissions-cell {
  text-align: end;
  width: var(--spacing-20x);
}

.actions-cell {
  width: var(--spacing-12x);
}

.remove-member-button {
  border: none;
  background-color: transparent;
  color: var(--color-fg-40);
  font-size: var(--font-size-m);
  cursor: pointer;
}

.remove-member-button:hover {
  color: var(--color-fg-70);
}

.remove-member-button:disabled {
  color: var(--color-bg-30);
  cursor: default;
}
