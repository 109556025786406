.privacy-policy {
  height: 100%;
  background-color: var(--color-bg-10);
}

.privacy-policy-title {
  background-color: var(--color-bg-20);
  padding: var(--spacing-04x) var(--spacing-08x);
  text-align: center;
}

.privacy-policy-container {
  display: flex;
  padding: var(--spacing-08x);
  justify-content: center;
  height: 100%;
}

.privacy-policy-content {
  display: flex;
  gap: var(--spacing-04x);
}

@media (max-width: 760px) {
  .privacy-policy-content > aside {
    display: none;
  }

  .privacy-policy-content {
    flex-direction: column;
  }
}
