.editor-header-devices-buttons {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-04x);
}

.size-indicator-wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-02x);
}

.size-indicator {
  font-variant-numeric: tabular-nums;
  border: var(--border-w-01) solid var(--color-fg-40);
  padding: var(--spacing-01x);
  border-radius: var(--border-r-s);
  background-color: transparent;
  color: var(--color-fg-40);
  width: var(--spacing-14x);
  display: flex;
  align-items: center;
  justify-content: center;
}
