.brands-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08x);
  color: var(--cb-80);
  background-color: var(--color-success-subdued);
  padding: var(--spacing-12x) 0;
  align-items: center;
  text-align: center;
}

.brands-section .brands-container {
  display: flex;
  gap: var(--spacing-16x);
  transform: scale(0.9);
}

.brand-logo {
  max-width: 10vw;
  max-height: 40px;
}

@media (max-width: 768px) {
  .brands-section {
    gap: var(--spacing-08x);
  }

  .brands-section .brands-container {
    margin: 0 var(--spacing-04x);
    gap: var(--spacing-02x);
    transform: scale(0.7);
  }

  .brand-logo {
    max-width: 30vw;
  }
}
