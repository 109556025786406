.frame-select-input {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--spacing-02x);
}

.frame-select-options {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: var(--spacing-04x);
}

.frame-select-input-loading {
  width: 50%;
  display: flex;
  flex-flow: column;
  gap: var(--spacing-04x);
}

.skeleton-item {
  display: flex;
  flex-flow: row;
  gap: var(--spacing-02x);
}

.frame-select-input-loading .skeleton-circle {
  flex: 0 0 auto;
}
