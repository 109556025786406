.billing-history-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  margin-top: var(--spacing-04x);
}

.billing-history-section h4 {
  width: 100%;
  border-bottom: 1px solid var(--color-bg-30);
  padding: var(--spacing-02x) 0;
}

.billing-history-section table {
  width: 100%;
  font-size: var(--font-size-s);
}

.billing-history-section th {
  font-weight: 400;
  outline: none;
  text-align: start;
  padding: var(--spacing-02x) 0;
}

.billing-history-section td {
  padding: var(--spacing-02x) 0;
}

.billing-history-section .status-cell {
  text-transform: capitalize;
}

.billing-history-section .invoice-cell a {
  width: fit-content;
  padding: 0;
}
