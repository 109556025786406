.fonts-container {
  display: flex;
  flex-direction: column;
}

.fonts-table > tbody > tr > th,
.fonts-table > tbody > tr > td {
  text-align: left;
  font-size: var(--font-size-s);
  line-height: var(--line-height-100);
  padding: var(--spacing-02x) var(--spacing-04x);
  vertical-align: top;
}

.fonts-table .center {
  text-align: center;
}

.error-message {
  padding: var(--spacing-04x);
}

.font-weight {
  width: 220px;
}
