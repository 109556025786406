.cannoli-services-section-one-column-bg-20 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-12x) 0;
  gap: var(--spacing-06x);
  background-color: var(--color-bg-20);
}

.cannoli-services-section-one-column-bg-20 a {
  color: var(--color-fg-80);
  text-decoration: underline;
}

.cannoli-services-anchor-button a {
  text-decoration: none;
}

.cannoli-services-section-card-styled {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  align-items: center;
  padding: var(--spacing-06x) var(--spacing-12x);
  text-align: center;
  background-color: var(--color-bg-00);
  border-radius: var(--border-r-m);
  box-shadow: var(--shadow-01);
  width: 568px;
}

.cannoli-services-section-title-domine-number {
  height: var(--spacing-12x);
  width: var(--spacing-12x);
  border-radius: 50%;
  background-color: var(--color-secondary-02);
}

.cannoli-services-section-list-flex {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  padding-left: var(--spacing-03x);
}

.cannoliTitleNumber {
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Domine;
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-bg-00);
}

.cardContent {
  max-width: 472px;
  text-align: left;
}

@media (max-width: 768px) {
  .cannoli-services-section-one-column-bg-20 {
    padding: var(--spacing-08x) 24px;
  }

  .cannoli-services-section-card-styled {
    width: 100%;
    padding: var(--spacing-08x) var(--spacing-04x);
  }

  .cannoli-services-section-title-domine-number {
    height: var(--spacing-10x);
    width: var(--spacing-10x);
  }
}
