:root {
  --bg-color-replace-overlay: #17121e;
}

.container {
  position: relative;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: var(--bg-color-replace-overlay);
  z-index: 1;
  pointer-events: none;
  box-sizing: border-box;
}

.overlay > span {
  z-index: 2;
  border-radius: var(--border-r-s) 0 0 0;
  font-family: var(--font-family-inter);
  font-size: var(--spacing-03x);
  font-weight: var(--font-weight-semi-bold);
  padding: var(--spacing-02x);
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  color: var(--color-bg-00);
  pointer-events: none;
}

.success.overlay::before {
  border: var(--border-w-02) dashed var(--color-primary-01);
}

.success span {
  background-color: var(--color-primary-01);
}

.error.overlay::before {
  border: var(--border-w-02) dashed var(--color-danger-01);
}

.error span {
  background-color: var(--color-danger-01);
}
