.font-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.font-container > div > span {
  font-size: var(--font-size-s);
  line-height: var(--line-height-100);
}

.font-container > div {
  width: 50%;
  flex-grow: 1;
}

.font-fallback {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.error-message {
  font-size: var(--font-size-s);
  line-height: var(--line-height-100);
  text-align: center;
}
