.step-container {
  display: flex;
  flex-direction: row;
  padding: var(--spacing-08x);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-12x);
  align-self: stretch;
  border-radius: var(--border-r-m);
  background: var(--color-bg-00);
}

.step-image {
  max-width: 300px;
}

.step-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-04x);
  flex: 1 0 0;
  align-self: stretch;
}

.step-link {
  color: var(--color-fg-80);
  font-style: normal;
  text-decoration-line: underline;
}

@media (max-width: 768px) {
  .step-container {
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-04x);
  }

  .step-image {
    max-width: 100%;
  }

  .step-image img {
    width: 100%;
  }
}
