.header-private {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--cb-90);
  width: 100%;
  padding: var(--spacing-02x) var(--spacing-08x);
}

.header-private a {
  color: var(--color-bg-00);
  text-decoration: none;
}

.header-private-logo-wrapper {
  display: flex;
  align-content: center;
}

.header-private-cannoli-logo {
  width: 170px;
  height: var(--spacing-10x);
}

.header-private-cannoli-logo:hover {
  color: var(--color-primary-01);
  transition: all 0.2s ease;
}
