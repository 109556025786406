.integration-add-modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.integration-add-modal-content img,
.integration-add-modal-content h3 {
  align-self: center;
}

.integration-image-container {
  margin: auto;
  display: flex;
  align-items: center;
  gap: var(--spacing-04x);
}

.modal-icon {
  font-size: var(--spacing-06x);
}

.logo-image {
  max-height: var(--size-12);
  max-width: var(--size-12);
}
