.privacy-policy-text-wrapper {
  padding: var(--spacing-08x) var(--spacing-06x) var(--spacing-04x)
    var(--spacing-12x);
  border-radius: var(--border-r-xl);
  background-color: var(--color-bg-00);
}

.privacy-policy-text {
  background-color: var(--color-bg-00);
  display: flex;
  flex-direction: column;
  padding-right: var(--spacing-04x);
  gap: var(--spacing-04x);
  max-width: 680px;
  max-height: 70vh;
  overflow-y: scroll;
}

.privacy-policy-text-wrapper ::-webkit-scrollbar-thumb {
  background: var(--color-bg-20);
  border-radius: var(--border-r-m);
}

.privacy-policy-text-wrapper ::-webkit-scrollbar {
  width: var(--spacing-01x);
}

.privacy-policy-text-wrapper ::-webkit-scrollbar-track {
  background: var(--color-bg-10);
  border-radius: var(--border-r-m);
}

.privacy-policy-text section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.privacy-policy-text a {
  color: var(--color-primary-01);
}

.privacy-policy-text ul {
  margin: 0;
}
