.dashboard-template-form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  height: 100%;
}

.dashboard-template-form-header {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
  font-weight: 600;
}

.dashboard-template-form-header svg {
  height: var(--spacing-06x);
}

.dashboard-template-form-content {
  border-radius: var(--border-r-xl);
  padding: var(--spacing-04x);
  background-color: var(--color-bg-00);
  height: 100%;
}
