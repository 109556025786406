.divider {
  height: 3px;
  background-color: #dbd3ff;
  max-width: 90%;
  margin: 0 auto;
}

.quote-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--spacing-16x);
}

.quote-section-wrapper {
  display: flex;
  gap: var(--spacing-08x);
  max-width: 900px;
  margin-top: var(--spacing-16x);
  align-items: center;
}

.quote-section-wrapper .text-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.quote-section-wrapper .speaker-image {
  max-width: 40%;
  object-fit: contain;
}

.quote-section-wrapper .quote {
  text-align: start;
  margin: var(--spacing-04x) 0;
  color: var(--fg-60, #323245);
  font-feature-settings: 'clig' off, 'liga' off;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  max-width: 474px;
}

.quote-section-wrapper .speaker-text {
  text-align: start;
  color: var(--fg-40, #727297);
  font-feature-settings: 'clig' off, 'liga' off;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media (max-width: 768px) {
  .quote-section-wrapper {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-04x);
  }

  .quote-section-wrapper .speaker-image {
    max-width: 50%;
  }

  .quote-section-wrapper .quote {
    text-align: center;
    font-size: var(--font-size-l);
    font-weight: 700;
    line-height: 1.8;
    margin: 0 var(--spacing-06x);
  }

  .quote-section-wrapper .speaker-text {
    text-align: center;
    margin: 0 var(--spacing-06x);
  }
}
