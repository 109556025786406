.cta-section {
  padding: 0 var(--spacing-08x);
}

.cta-wrapper {
  width: 100%;
  max-width: 1240px;
  padding: var(--spacing-16x) 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-06x);
  margin: 0 auto;
  text-align: center;
}

.cta-subtitle {
  font-size: var(--font-size-l);
  color: var(--cb-60);
}

@media (max-width: 768px) {
  .cta-section {
    padding: 0 var(--spacing-04x);
  }

  .cta-wrapper {
    padding: var(--spacing-08x) 0;
  }
}
