.editor-sidebar {
  --bg-color: var(--cb-80);

  display: flex;
  flex-direction: column;
  font-size: var(--font-size-m);
  padding: 20px var(--spacing-02x);
  background-color: var(--bg-color);
  width: var(--spacing-14x);
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
  z-index: 10;
  transition: all 0.2s ease;
}

.editor-sidebar ::-webkit-scrollbar-thumb {
  background: var(--cb-40);
}

.editor-sidebar-header {
  display: flex;
  height: var(--spacing-06x);
  justify-content: space-between;
  padding: 0;
  align-items: center;
}

.editor-sidebar-top {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  align-items: center;
}

.divider {
  margin: 0;
  height: 2px;
  width: 100%;
  border: none;
  background-color: #26263a;
}

.editor-sidebar-bottom {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.editor-sidebar.collapsed {
  width: var(--spacing-14x);
  padding: var(--spacing-04x) var(--spacing-02x);
}

.user-actions-container {
  display: flex;
  flex-direction: column;
}
