.header-logo-container {
  display: block;
  transition: 0.2s ease all;
  color: var(--color-bg-00);
  min-width: var(--spacing-06x);
}

.header-logo-container:hover {
  color: var(--color-primary-01);
}

.header-logo-container .logo {
  flex: 1 0 auto;
  height: 100%;
  width: auto;
  display: block;
}
