.sidebar-label-item-container {
  display: flex;
  align-items: center;
  border-radius: var(--border-r-m);
  transition: ease-out 0.2s;
  font-weight: 600;
  font-size: var(--font-size-s);
  position: relative;
  border-left: 4px solid transparent;
}

.sidebar-label-item-container:hover {
  background-color: var(--purple-03);
  border-radius: var(--border-r-sharp) var(--border-r-m) var(--border-r-m)
    var(--border-r-sharp);
  border-left: 4px solid var(--color-secondary-02);
}

.sidebar-label-item-container:active {
  background-color: var(--purple-02);
  border-radius: var(--border-r-sharp) var(--border-r-m) var(--border-r-m)
    var(--border-r-sharp);
  border-left: 4px solid var(--color-secondary-02);
}

.sidebar-label-item-container a:first-of-type {
  width: 100%;
  text-decoration: none;
  padding: var(--spacing-02x);
}

.sidebar-label-item-container svg {
  font-size: var(--spacing-06x);
}

.sidebar-label-item-container.active {
  background-color: var(--purple-03);
  border-radius: var(--border-r-sharp) var(--border-r-m) var(--border-r-m)
    var(--border-r-sharp);
  border-left: 4px solid var(--color-secondary-02);
}

.sidebar-label-item-container.active:hover {
  border-radius: var(--border-r-sharp) var(--border-r-m) var(--border-r-m)
    var(--border-r-sharp);
  border-left: 4px solid var(--color-secondary-02);
}

.sidebar-label-item-title {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
}

.sidebar-label-item-title p {
  max-width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar-label-item-actions {
  margin: auto var(--spacing-02x) auto 0;
}

[data-disabled='true'] {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
