.image-preview-container {
  font-size: var(--font-size-s);
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: var(--spacing-03x);
  gap: var(--spacing-04x);
}

.image-preview-container > div:first-child {
  width: 90px;
  height: 90px;
  background: var(--img-bg-transparent);
  border-radius: var(--border-r-s);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-01x);
}

.image-preview-container img {
  max-height: 100%;
  max-width: 100%;
}

.image-details-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.image-size {
  color: var(--color-warning-01);
}
