.editor-panel-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background-color: var(--color-bg-20);
  transition: 0.5s;
  color: var(--color-fg-80);
  padding: var(--spacing-03x) var(--spacing-02x) var(--spacing-12x);
}

.editor-panel-container > div {
  width: 100%;
}

.editor-panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-m);
  line-height: var(--line-height-100);
  padding-bottom: var(--spacing-04x);
}

.editor-panel-header svg:hover {
  cursor: pointer;
}

.fonts-message {
  color: var(--color-fg-40);
  font-size: var(--font-size-s);
  line-height: var(--line-height-165);
  padding-bottom: var(--spacing-02x);
}

.missing-fonts {
  padding-top: var(--spacing-04x);
}

.error-message {
  font-size: var(--font-size-s);
  line-height: var(--line-height-100);
  text-align: center;
}
