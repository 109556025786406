.dashboard-team-members {
  background-color: var(--color-bg-00);
  border-radius: var(--border-r-xl);
  padding: var(--spacing-04x);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06x);
}

.team-members-header-container {
  display: flex;
  gap: var(--spacing-04x);
  align-items: center;
}

.team-members-header-container p {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
  font-size: var(--font-size-s);
  padding: var(--spacing-04x);
  border-radius: var(--border-r-xl);
  color: var(--color-danger-01);
  background-color: rgba(255 0 0 / 10%);
}
