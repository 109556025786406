.compact-card {
  outline: 1px solid var(--color-bg-20);
  border-radius: var(--spacing-04x);
  display: flex;
  font-size: var(--font-size-s);
  color: var(--color-fg-80);
  text-decoration: none;
  align-items: center;
  background-color: var(--color-bg-00);
}

.compact-card-thumbnail {
  background-color: var(--color-extra-01);
  border-radius: var(--spacing-04x) 0 0 var(--spacing-04x);
  width: var(--spacing-20x);
  min-width: var(--spacing-20x);
  color: var(--color-bg-00);
  font-size: var(--spacing-08x);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.compact-card-thumbnail img {
  border-radius: var(--spacing-04x) 0 0 var(--spacing-04x);
  height: 100%;
  object-fit: cover;
}

.compact-card-content {
  padding: var(--spacing-04x);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.compact-card-title {
  font-size: var(--font-size-m);
  font-weight: 600;
}
