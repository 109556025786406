.integrations-page {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  border-radius: var(--border-r-xl);
  padding: var(--spacing-04x);
  background-color: var(--color-bg-00);
  gap: var(--spacing-04x);
}

.integrations-container {
  max-width: 900px;
}
