.pricing-section {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-16x) 0;
  align-items: center;
  background: var(--cb-10);
}

.pricing-section a {
  width: 100%;
}

.pricing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-08x);
  max-width: 1100px;
}

.pricing-container > p {
  text-align: center;
}

.pricing-cards-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: var(--spacing-06x);
}

.pricing-cards-container > article {
  flex-grow: 1;
  flex-basis: 0;
}

.solo-creators-container {
  display: flex;
  padding: var(--spacing-02x) var(--spacing-04x) var(--spacing-04x);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-02x);
  flex: 1 0 0;
  border-radius: var(--spacing-02x);
  background: var(--color-secondary-subdued);
  flex-grow: 2;
  flex-basis: 0;
}

.solo-creators-container > p {
  font-weight: var(--font-weight-semi-bold);
}

.solo-creators-plans {
  display: flex;
  align-items: stretch;
  gap: var(--spacing-04x);
  height: 100%;
}

.solo-creators-plans > article {
  flex-grow: 1;
  flex-basis: 0;
}

@media (max-width: 1000px) {
  .pricing-section {
    padding: var(--spacing-06x);
  }

  .pricing-cards-container {
    flex-direction: column-reverse;
  }

  .solo-creators-plans {
    flex-direction: row-reverse;
  }
}

@media (max-width: 760px) {
  .pricing-cards-container {
    width: 100%;
  }

  .solo-creators-plans {
    flex-direction: column-reverse;
    width: 100%;
  }
}
