.card-iframe-wrapper {
  overflow: visible;
  width: 200% !important;
  height: 200% !important;
}

.card-iframe {
  border: none;
  width: 100%;
  height: 200%;
  pointer-events: none;
}
