.forno-resize-gutter {
  width: 100%;
  height: 100%;
  display: block;
  background: var(--cb-95);
  position: relative;
  cursor: ew-resize;
}

.forno-resize-gutter::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 181px;
  display: block;
  border-radius: var(--border-r-s);
  background: var(--cb-60);
  transform: translate(-50%, -50%);
}
