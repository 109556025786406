.card {
  display: flex;
  flex-direction: column;
  color: var(--cb-70);
  text-decoration: none;
  background-color: var(--color-bg-00);
  border-radius: var(--border-r-xl);
  box-shadow: 0 6px 10px rgba(0 0 0 / 8%), 0 0 6px rgba(0 0 0 / 5%);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.03);
  box-shadow: 0 10px 20px rgba(0 0 0 / 12%), 0 4px 8px rgba(0 0 0 / 6%);
}

.card-image {
  width: 100%;
  border-radius: var(--spacing-04x) var(--spacing-04x) 0 0;
}

.card-text-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: var(--spacing-06x);
  gap: var(--spacing-02x);
}
