.new-team-page {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08x);
  padding: var(--spacing-20x);
}

.new-team-page-footer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}
