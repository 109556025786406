.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-16x) 0;
}

.hero-section-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08x);
  max-width: 1100px;
  align-items: center;
}

.hero-section-container h1,
.hero-section-container p {
  text-align: center;
}

.hero-section-video-mobile {
  display: none;
}

.hero-section .get-started {
  padding: var(--spacing-04x) var(--spacing-06x);
  font-weight: var(--font-weight-semi-bold);
  margin: 0;
}

.hero-section .try-cannoli-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-04x);
}

.hero-section .link {
  cursor: pointer;
  text-decoration: underline;
}

.hero-section-video-desktop {
  max-width: 800px;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 0;
    gap: var(--spacing-04x);
  }

  .hero-section-container {
    padding: var(--spacing-06x);
  }

  .try-cannoli-container {
    width: 100%;
  }

  .try-cannoli-container > button {
    width: 100%;
  }

  .hero-section .try-cannoli-container {
    flex-direction: column;
  }

  .hero-section-video-mobile {
    display: flex;
  }

  .hero-section-video-desktop {
    display: none;
  }
}
