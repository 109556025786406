.form-divider {
  border: none;
  background-color: var(--color-bg-20);
  height: 1px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
}

.form-wrapper button {
  align-self: flex-end;
}
