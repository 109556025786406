.editor-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-flow: row;
  color: var(--color-bg-00);
}

.editor-page-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-flow: column;
}

.editor-page {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  background: var(--color-fg-80);
}

.editor-page-left-bar {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 30%;
  max-width: 300px;
}

.editor-page-right-bar {
  flex: 1 1 auto;
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 30%;
  max-width: 320px;
}

.editor-page-content {
  display: flex;
  flex-flow: row;
  flex-grow: 1;
  overflow: hidden;
}

.alert-container {
  display: flex;
  justify-content: center;
  background-color: var(--cb-90);
}
