.hero-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06x);
  padding: 50px 0;
  align-items: center;
  text-align: center;
  max-width: 850px;
  margin: 0 auto;
}

.hero-section-image-mobile {
  display: none;
}

.hero-section-image-desktop {
  max-width: 560px;
}

.hero-section .get-started {
  padding: var(--spacing-04x) var(--spacing-06x);
  font-weight: 600;
  margin: 0;
}

.hero-section .try-cannoli-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-04x);
}

.hero-section .link {
  color: var(--cb-70);
  cursor: pointer;
  text-decoration: underline;
}

.hero-section-upvote-us {
  display: flex;
  align-items: center;
  gap: var(--spacing-03x);
}

.upvote-us-asset {
  max-width: 160px;
}

@media (max-width: 768px) {
  .hero-section {
    padding: var(--spacing-06x);
  }

  .hero-section p {
    font-size: var(--font-size-m);
  }

  .hero-section .try-cannoli-container {
    flex-direction: column;
  }

  .hero-section-image-mobile {
    display: flex;
  }

  .hero-section-image-desktop {
    display: none;
  }

  .hero-section-upvote-us {
    display: flex;
    flex-direction: column;
  }
}
