.dashboard-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-04x);
}

.dashboard-settings {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}
