.feature-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 500px;
  text-align: center;
  gap: var(--spacing-03x);
  padding: var(--spacing-06x);
  margin: auto;
  height: 100%;
}

.feature-block-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-06x);
}
