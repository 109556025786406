div[data-modal-type='custom-fonts-admin'] {
  width: clamp(90%, 80vw, 900px);
  max-width: 900px;
}

.custom-fonts-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.add-font {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-02x);
}

.dropdown-fonts {
  flex-grow: 1;
}

.font-option {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-02x);
}
