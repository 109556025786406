.create-account-page {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.create-account-page h1 {
  font-family: var(--font-02);
}

.create-account-page-cta {
  display: flex;
  gap: var(--spacing-04x);
}
