.file-sharing-content {
  width: 100%;
}

.file-sharing-toggle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
