.profile-page {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  border-radius: var(--border-r-xl);
  padding: var(--spacing-04x);
  background-color: var(--color-bg-00);
  gap: var(--spacing-04x);
}

.profile-page-user-form {
  margin-bottom: var(--spacing-08x);
  width: 100%;
}

.profile-information-title {
  width: 100%;
  text-align: left;
  margin-bottom: var(--spacing-04x);
}
