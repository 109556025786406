.editor-panel-container {
  height: calc(100vh - 190px);
  overflow-y: auto;
}

.body-children {
  display: flex;
  flex-direction: column;
}

.body-children-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--color-fg-40);
  padding: var(--spacing-02x);
  height: fit-content;
  transition: background-color 0.2s ease, color 0.2s ease;
  user-select: none;
  background-color: transparent;
  border: 0;
}

.body-children-tag:hover {
  background-color: #8a7ac8;
  color: var(--color-fg-95);
  cursor: pointer;
}

.body-children-tag:active {
  cursor: grabbing;
}

.body-children-tag-name {
  display: grid;
  grid-template-columns: var(--spacing-04x) 1fr max-content;
  align-items: center;
  gap: var(--spacing-02x);
}

.body-children-buttons {
  visibility: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: var(--spacing-04x);
}

.body-children-tag:hover .body-children-buttons {
  visibility: visible;
}

.body-children-tag-name-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 10vw;
}

.children-tag-menu {
  visibility: hidden;
}

.body-children-tag:hover .children-tag-menu {
  visibility: visible;
}

.body-children-tag[data-selected='true'] {
  background-color: var(--color-info-03);
  color: var(--color-fg-95);
}

.body-children-tag[data-selected='true'] .body-children-buttons {
  visibility: visible;
}

.body-children-tag[data-selected='true'] .children-tag-menu {
  visibility: visible;
}

.acordeon-children {
  display: flex;
  align-items: flex-start;
  padding: var(--spacing-02x);
  padding-left: var(--spacing-14x);
  color: var(--color-fg-40);
  border: 0;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  width: 100%;
}

.acordeon-children:hover {
  background-color: var(--color-info-03);
  color: var(--color-fg-95);
}

.acordeon-children[data-selected='true'] {
  background-color: var(--color-info-03);
  color: var(--color-fg-95);
}

.body-children-collapse-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.node-children {
  padding-left: var(--spacing-04x);
}

.drag-indicator {
  cursor: grab;
}

.add-block-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--spacing-04x);
}
