.input-url-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01x);
}

.input-url-container label {
  font-size: var(--font-size-s);
}

.input-url {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-03x);
}

.input-url > div:first-child {
  flex-grow: 1;
}

.input-url > div:nth-child(2) {
  padding-top: var(--spacing-01x);
}

.upload-button {
  width: 100%;
}
