.container {
  display: flex;
  align-items: center;
}

.container > div {
  flex-basis: 33.33%;
  flex-grow: 1;
}

.pagination-container {
  display: flex;
}

.pagination-container > nav {
  margin: auto;
  padding: var(--spacing-02x);
}

.pagination-list {
  display: flex;
  flex-flow: row;
  padding: 0;
  margin: 0;
  gap: var(--spacing-02x);
}

.pagination-item {
  list-style-type: none;
}

.pagination-page-size {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-02x);
}
