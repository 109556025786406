.integration-block {
  padding: var(--spacing-04x);
  border-radius: var(--border-r-xl);
  display: grid;
  gap: var(--spacing-04x);
  align-content: center;
  grid-template-columns:
    1fr minmax(var(--spacing-06x), auto)
    minmax(138px, auto);
}

.integration-block button {
  align-self: center;
}

.integration-block-main {
  display: flex;
}

.image-container {
  min-width: var(--spacing-16x);
  display: flex;
  align-items: center;
}

.integration-block .logo-image {
  border-radius: var(--border-r-m);
  align-self: flex-start;
  margin: auto;
  max-height: var(--spacing-10x);
  max-width: var(--spacing-10x);
}

.integration-block-information {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.integration-block-information p {
  font-size: var(--font-size-s);
}

.integration-block-header {
  display: flex;
  gap: var(--spacing-02x);
  align-content: center;
  width: 100%;
}

.integration-block-header h5 {
  margin: auto 0;
}

.connected-tag {
  color: var(--color-success-02);
  background-color: rgba(27 192 122 / 10%);
  font-size: var(--font-size-s);
  border: 1px solid var(--color-success-02);
  border-radius: var(--border-r-xl);
  padding: 2px var(--spacing-01x);
}
