.user-avatar-container {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-02x);
  align-items: center;
}

.user-name {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--font-size-s);
}

.forno-avatar-wrapper[data-size='6'] div {
  height: 6rem;
  width: 6rem;
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--line-height-100);
}

.forno-avatar-wrapper[data-size='6'] img {
  height: 6rem;
  width: 6rem;
}
