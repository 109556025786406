.dropdown-menu-container {
  position: relative;
  font-size: var(--font-size-s);
  line-height: var(--line-height-100);
}

.dropdown-menu-container > div > span {
  font-weight: var(--font-weight-semi-bold);
}

.dropdown-menu-container > div > span:hover {
  cursor: default;
}

.dropdown-menu-toggle {
  background: transparent;
  border-radius: var(--border-r-s);
  color: var(--color-fg-40);
  padding: var(--spacing-01x);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dropdown-menu-content {
  background-color: var(--color-bg-20);
  border: var(--border-w-01) solid var(--color-fg-40);
  border-radius: var(--border-r-s);
  box-shadow: 0 var(--spacing-01x) var(--spacing-03x) rgba(2 0 8 / 15%);
  width: 100%;
  min-width: 186px;
  padding: var(--spacing-01x);
  z-index: 2;
}

.dropdown-menu-content button {
  margin-top: var(--spacing-02x);
  width: 100%;
}

.options-container {
  height: 200px;
  overflow-y: scroll;
}

.no-options {
  padding: var(--spacing-02x);
}
