.new-team-form {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  margin-bottom: var(--spacing-16x);
}

.new-team-form-error {
  font-size: var(--font-size-s);
  color: var(--color-danger-01);
  margin-top: var(--spacing-01x);
}
