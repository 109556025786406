.input-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-02x) 0;
  gap: var(--spacing-01x);
}

.input-item {
  width: 100%;
}

:global(.forno-input-icon svg:hover) {
  cursor: pointer;
}
