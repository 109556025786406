html {
  font-size: 16px;
}

.cannoli-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cannoli-page h1,
.cannoli-page h2,
.cannoli-page h3 :not(footer),
.cannoli-page h4,
:global(.paragraph-l),
:global(.paragraph-sl),
:global(.paragraph-r),
:global(.paragraph-s) {
  color: var(--color-fg-80);
  font-style: normal;
}

.cannoli-page h1,
.cannoli-page h2 {
  font-family: var(--font-02);
}

.cannoli-page h3,
.cannoli-page h4,
:global(.paragraph-l),
:global(.paragraph-sl),
:global(.paragraph-r),
:global(.paragraph-s) {
  font-family: var(--font-family-inter);
}

.cannoli-page h1 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 135%;
}

.cannoli-page h2 {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: normal;
}

.cannoli-page h3 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 150%;
}

.cannoli-page h4 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
}

.cannoli-page h5,
.cannoli-page h6 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 150%;
}

:global(.paragraph-l) {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 150%;
}

:global(.paragraph-sl) {
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
}

:global(.paragraph-r) {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 175%;
}

:global(.paragraph-s) {
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 175%;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 12px;
  }

  .cannoli-page :global(button),
  .cannoli-page :global(.forno-button) :not(footer) {
    width: 100%;
    white-space: pre-line;
  }
}
