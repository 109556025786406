.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-06x);
  text-align: center;
  padding: var(--spacing-12x) 0;
}

.section svg {
  color: var(--color-success-01);
}

.section p a {
  color: var(--color-fg-80);
  text-decoration: underline;
}
