.personal-billing-pricing {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06x);
}

.personal-billing-pricing-message {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  padding: var(--spacing-04x);
}

.personal-billing-pricing-title {
  display: flex;
  align-items: center;
  gap: var(--spacing-02x);
}

.personal-billing-pricing-title svg {
  font-size: var(--font-size-l);
  color: var(--color-warning-01);
}
