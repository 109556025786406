.dashboard-sidebar-bottom {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.help-center-container button {
  justify-content: center;
}

.user-actions-container {
  display: flex;
  flex-direction: column;
}

.user-info-container {
  border-radius: var(--border-r-m);
  align-items: center;
  transition: all 0.2s ease;
  position: relative;
}

.user-info-container::after {
  border-radius: var(--border-r-m);
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: var(--gradient-01);
  opacity: 0;
  transition: opacity 0.2s ease;
  cursor: pointer;
  z-index: -1;
}

.user-info-container:hover::after {
  opacity: var(--opacity-10);
}

.user-info-container button {
  justify-content: center;
}

.user-info-container:active::after {
  background-image: var(--spacing-04x);
}

.divider {
  margin: 0;
  height: 2px;
  width: 100%;
  border: none;
  background-color: #26263a;
}

.accordion-header {
  background-color: transparent;
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.accordion-body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  margin-left: var(--spacing-06x);
  gap: var(--spacing-01x);
}

.accordion-body.collapsed {
  margin-left: 0;
}

.accordion-header-svg {
  position: absolute;
  color: var(--cb-10);
  right: var(--spacing-02x);
  top: var(--spacing-02x);
  pointer-events: none;
}
