.order-summary {
  width: 380px;
  height: fit-content;
  padding: var(--spacing-04x);
  box-shadow: var(--shadow-01);
  border-radius: var(--border-r-m);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-03x);
  background-color: var(--color-bg-00);
}

.order-summary-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-01x);
}

.order-summary .order-summary-header svg {
  color: var(--color-fg-80);
}

.order-summary-pricing-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.order-summary-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-04x) 0 0 0;
  border-top: solid 1px var(--color-bg-20);
}

.order-summary-details p {
  line-height: 150%;
}

.order-summary button {
  width: 100%;
}

.order-summary-tag {
  background-color: var(--color-fg-50);
  padding: var(--spacing-02x);
  border-radius: var(--border-r-m);
}

.order-summary-tag h4 {
  color: var(--color-bg-00);
}

.bold {
  font-weight: 600;
}

.order-summary-policies-anchors,
.order-summary-policies-anchors a {
  color: var(--color-fg-40);
}

.order-summary-policies-anchors a {
  text-decoration: underline;
}

.order-summary-newsletter {
  color: var(--color-fg-40);
}

@media (max-width: 768px) {
  .order-summary {
    width: 100%;
  }

  .order-summary-details button {
    width: 100%;
  }
}
