.user-avatar-form {
  display: flex;
  flex-flow: row;
  gap: var(--spacing-04x);
  align-items: center;
  justify-content: flex-start;
}

.user-avatar-info {
  display: flex;
  flex-flow: column;
  gap: var(--spacing-04x);
}
