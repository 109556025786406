.profile-menu [data-reach-menu-button] {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: var(--font-size-s);
  gap: var(--spacing-02x);
  width: fit-content;
  height: fit-content;
}

.profile-menu [data-reach-menu-button] svg {
  font-size: var(--spacing-04x);
}

.profile-menu [data-reach-menu-button]:hover {
  border-color: var(--color-bg-30);
}

.profile-menu [data-reach-menu-button]:active {
  border-color: var(--color-bg-30);
}

.menu-popover[data-reach-menu-list] [data-reach-menu-item] {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-02x);
  align-items: center;
  transition: all ease 0.2s;
  color: var(--color-fg-80);
}

.menu-popover[data-reach-menu-list]
  [data-reach-menu-item][data-item-selected='true'] {
  background-color: var(--color-bg-10);
  border-left: 3px solid var(--color-secondary-01);
}

.menu-popover[data-reach-menu-list] {
  padding: var(--spacing-02x) 0;
  margin: 0;
  background-color: var(--color-bg-30);
  color: var(--color-fg-70);
  border-radius: var(--border-r-s);
  border: 1px solid var(--color-bg-30);
}

.menu-popover[data-reach-menu-list] [data-reach-menu-item]:hover {
  background-color: var(--color-bg-10);
  color: var(--color-fg-80);
}

.menu-popover[data-reach-menu-list] [data-reach-menu-item]:active {
  color: var(--color-fg-70);
  background-color: var(--color-bg-10);
}

.button-action-icon {
  display: none;
}

.profile-menu [data-reach-menu-button]:hover .button-action-icon {
  display: block;
}

.menu-item p {
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.menu-item-new div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-02x);
  width: fit-content;
  margin: auto;
  padding: var(--spacing-01x);
}
