.radio-button-container {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
}

.radio-button-container input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
}

.radio-button-label {
  text-transform: capitalize;
}

.radio-button-checkmark {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--spacing-04x);
  height: var(--spacing-04x);
  border-radius: var(--border-r-xl);
  background-color: transparent;
  border: 1px solid var(--color-bg-10);
  margin-right: var(--spacing-02x);
}

.radio-button-checkmark svg {
  visibility: hidden;
}

.-checked {
  background-color: var(--color-primary-01);
  border-color: var(--color-primary-01);
}

.-checked :hover {
  border-color: var(--color-main-02);
}

.-checked svg {
  visibility: visible;
}

.-disabled input {
  cursor: not-allowed;
}

.-disabled .radio-button-checkmark {
  border-color: var(--color-bg-30);
}

.-disabled:hover .radio-button-checkmark {
  border-color: var(--color-bg-30);
}

.-disabled .radio-button-checkmark svg {
  color: var(--color-bg-30);
}

.-disabled .radio-button-checkmark.-checked {
  background-color: transparent;
}

.-disabled .radio-button-label {
  color: var(--color-bg-30);
}

.radio-button-container:hover .radio-button-checkmark {
  border-color: var(--color-primary-01);
}
