.integration-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menu-popover[data-reach-menu-list] {
  padding: var(--spacing-02x) 0;
  margin: 0;
  background-color: var(--color-bg-00);
  color: var(--color-fg-70);
  border-radius: var(--border-r-s);
  border: 1px solid var(--color-bg-30);
}

.menu-popover[data-reach-menu-list] [data-reach-menu-item] {
  display: flex;
  gap: var(--spacing-02x);
  align-items: center;
  transition: all ease 0.2s;
}

.menu-popover[data-reach-menu-list] [data-reach-menu-item]:hover {
  background-color: var(--color-bg-30);
  color: var(--color-fg-80);
}

.menu-popover[data-reach-menu-list] [data-reach-menu-item]:active {
  color: var(--color-fg-70);
  background-color: var(--color-bg-30);
}

.button-action-icon {
  display: none;
}

.integration-menu [data-reach-menu-button]:hover .button-action-icon {
  display: block;
}
