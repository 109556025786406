.editor-content {
  flex-grow: 1;
}

.editor-content-container {
  flex: 1 1 100%;
  overflow: hidden;
  display: grid;
  height: 100%;
}

.editor-content-container > section {
  flex: 1 1 50%;
}

.editor-content-container[data-view='split'] {
  grid-template-columns: 1fr 1fr;
}

.editor-content-container[data-view='code'] {
  grid-template-columns: 1fr;
}
