.editor-panel-container {
  position: relative;
  background-color: var(--color-bg-20);
  transition: 0.5s;
  height: 100%;
  color: var(--color-fg-80);
  display: flex;
  flex-direction: column;
}

.editor-panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-m);
  line-height: var(--line-height-100);
  padding: var(--spacing-04x) var(--spacing-02x) var(--spacing-03x)
    var(--spacing-02x);
}

.editor-panel-label-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-01x);
  padding: var(--spacing-02x) 0 0 var(--spacing-02x);
  background-color: var(--color-bg-20);
  text-align: center;
  color: var(--color-warning-01);
}

[role='tablist'] {
  padding-left: var(--spacing-02x);
  grid-area: 1/2/1/3;
  margin-bottom: var(--spacing-02x);
}

[role='tabpanel'] {
  grid-column: 1/3;
  grid-row: 2/3;
}

.editor-panel-tabs {
  padding: var(--spacing-02x) 0;
  flex: 1;
  height: max-content;
  grid-area: 1/1/1/2;
}

.editor-add-blocks-button {
  grid-area: 2/1/1/2;
  justify-self: end;
}
