.preview-wrapper {
  overflow-y: auto;
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
}

.message-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}

.message-container span {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-04x);
}
