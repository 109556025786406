.container {
  padding: 0 var(--spacing-02x);
}

.editor-panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-m);
  line-height: var(--line-height-100);
  padding: var(--spacing-03x) var(--spacing-02x) 0 var(--spacing-02x);
}

.editor-panel-header svg:hover {
  cursor: pointer;
}

.component-container {
  border-bottom: solid var(--border-w-02) var(--color-bg-10);
  padding: var(--spacing-04x) var(--spacing-02x);
}

.component-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-02x);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semi-bold);
  padding-top: var(--spacing-02x);
}

.no-layer-selected {
  font-size: var(--font-size-m);
  line-height: var(--line-height-160);
  padding: var(--spacing-09x) var(--spacing-04x);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  text-align: center;
}

.no-layer-selected > span {
  font-size: var(--font-size-l);
}

.editor-panel-actions button {
  width: 100%;
}
