.videoWrapper {
  position: relative;
}

.videoControls {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(#000 0.5);
  padding: 5px;
}

.button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.rangeInput {
  flex: 1;
  margin: 0 10px;
}

.playButtonWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.playButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 114px;
  border-radius: 50%;
  background-color: #12083c;
  border: none;
  cursor: pointer;
  color: #1defb5;
  padding: 0;
}

.playIconOffset {
  margin-left: 5px;
}

/* Responsive */

@media (max-width: 768px) {
  .videoWrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  video {
    width: 100%;
    height: 100%;
  }

  .playButton {
    width: 60px !important;
    height: 60px !important;
  }

  .playButton svg {
    transform: scale(0.7);
  }
}
