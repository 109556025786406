.dashboard-project-information {
  padding: var(--spacing-04x);
  background-color: var(--color-bg-00);
  border-radius: var(--border-r-xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06x);
}

.dashboard-project-details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06x);
  max-width: 920px;
  width: 40%;
}

.divider {
  background-color: var(--color-bg-20);
  height: 1px;
  border: none;
  width: 100%;
  margin: 0;
}

.project-team-dropdown-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  font-size: var(--font-size-s);
}

.dashboard-project-delete {
  display: flex;
  justify-content: flex-end;
}
