.dashboard-billing {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-06x);
  padding: var(--spacing-04x);
  border-radius: var(--border-r-xl);
  background-color: var(--color-bg-00);
}

.dashboard-billing-content {
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-08x);
}

.dashboard-billing > a {
  align-self: flex-start;
}

.dashboard-billing > hr {
  margin: 0;
  border: none;
  background-color: var(--color-bg-20);
}
