.parse-test-page-form {
  display: flex;
  flex-flow: column;
  gap: var(--spacing-02x);
}

.parse-test-page-form form {
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: var(--spacing-02x);
}

.parse-test-page-form input {
  width: 100%;
}

.parse-test-page-form button {
  margin-left: auto;
  width: max-content;
}
