:root {
  --bg-color-overlay: #f9f9ff;
}

.overlay-node,
[data-interactive] {
  box-sizing: border-box;
  border-radius: var(--border-r-s);
  cursor: pointer;
  position: absolute;
  transition: border 0.2s ease, width 0.2s ease, height 0.2s ease;
}

.overlay-node {
  pointer-events: none;
}

[data-interactive] {
  pointer-events: visiblefill;
}

[data-interactive]::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: var(--bg-color-overlay);
}

.overlay-node span,
[data-interactive] span {
  border-radius: var(--border-r-s) 0 0 0;
  font-family: var(--font-family-inter);
  font-size: var(--spacing-03x);
  font-weight: var(--font-weight-semi-bold);
  padding: var(--spacing-02x);
  position: absolute;
  top: -2px;
  left: -2px;
  white-space: nowrap;
}

.overlay-node span {
  background-color: var(--color-info-01);
  color: var(--color-bg-00);
}

.label-normal {
  background-color: var(--color-primary-01);
  color: var(--color-bg-00);
}

.label-danger {
  background-color: var(--color-danger-01);
  color: var(--color-bg-00);
}

.overlay-node.-dashed {
  border: var(--border-w-02) dashed var(--color-info-01);
}

.dashed-normal[data-interactive] {
  border: var(--border-w-02) dashed var(--color-primary-01);
}

.dashed-danger[data-interactive] {
  border: var(--border-w-02) dashed var(--color-danger-01);
}

.-highlight {
  border: var(--border-w-02) solid var(--color-info-01);
}

.button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  width: 100%;
}

.dnd-indicator,
.delete-indicator {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
  align-items: center;
  font-family: sans-serif;
}

.dnd-indicator p {
  text-align: center;
  font-family: var(--font-family-inter);
  font-size: var(--spacing-03x);
  font-weight: var(--font-weight-semi-bold);
  margin: 0;
}

.dnd-indicator > button > svg {
  stroke-width: 0.2px;
}
