.services-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-06x);
  text-align: center;
  padding: var(--spacing-12x) 0;
}

a {
  text-decoration: none;
}

.pricing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-03x);
  padding: var(--spacing-04x);
  background-color: var(--color-secondary-subdued);
}

.cards-container {
  display: flex;
  align-items: stretch;
  gap: var(--spacing-04x);
  border-radius: var(--border-r-m);
  box-shadow: var(--shadow-01);
  max-width: 740px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-03x);
  padding: var(--spacing-06x);
  background-color: var(--color-bg-00);
  max-width: 348px;
}

.card ul {
  margin: 0;
  padding-left: var(--spacing-04x);
}

.card li {
  text-align: left;
  padding-bottom: var(--spacing-01x);
}

.card span {
  color: var(--cb-40);
}

.card div {
  flex-grow: 1;
  width: 100%;
}

.policy-button button {
  color: var(--cannoli-primary-01);
}

.align-left {
  text-align: left;
}

.coupon-section {
  display: flex;
  gap: var(--spacing-03x);
  max-width: 460px;
  text-align: left;
  padding: var(--spacing-03x) 0;
}

.coupon-section > div {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-03x);
}

.divider {
  height: 3px;
  background-color: #dbd3ff;
  max-width: 90%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .services-section {
    padding: var(--spacing-08x) 24px;
  }

  .cards-container {
    flex-direction: column;
  }

  .card {
    max-width: 100%;
  }
}
