.integration-update-modal-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.integration-update-modal-content img,
.integration-update-modal-content h3 {
  align-self: center;
}
