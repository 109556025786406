.message-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  font-size: var(--font-size-m);
  line-height: var(--line-height-160);
}

.message-container a {
  color: var(--color-fg-80);
  font-weight: var(--font-weight-semi-bold);
}

.support-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: var(--font-weight-semi-bold);
}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.checkbox-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-01x);
}

.checkbox-container > label {
  color: var(--color-fg-40);
  font-weight: var(--font-weight-semi-bold);
  margin-left: var(--spacing-01x);
  user-select: none;
}

.checkbox-container > input:hover,
.checkbox-container > label:hover {
  cursor: pointer;
}

.checkbox {
  visibility: hidden;
  margin: 0;
  height: var(--spacing-04x);
  width: var(--spacing-04x);
}

.checkbox::after {
  content: '';
  display: block;
  width: var(--spacing-04x);
  height: var(--spacing-04x);
  border: var(--border-w-02) solid var(--color-fg-40);
  border-radius: var(--border-r-s);
  background-color: transparent;
  visibility: visible;
  transition: background-color 0.2s ease, color 0.2s ease;
  cursor: pointer;
}

[data-checked='true'] .checkbox::after {
  border: var(--border-w-02) solid var(--color-info-02);
  background-color: var(--color-info-01);
}

.checkbox-check {
  position: absolute;
  visibility: hidden;
  color: var(--color-bg-00);
  stroke-width: 6px;
  top: 11px;
  left: 3px;
  pointer-events: none;
}

[data-checked='true'] .checkbox-check {
  visibility: visible;
}

.options-container {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-01x);
}

.options-container button:first-child,
.message-container a {
  text-decoration: underline;
}
