.editor-panel-container {
  position: relative;
  background-color: var(--color-bg-20);
  transition: 0.5s;
  display: flex;
  flex-flow: column;
  height: 100%;
  color: var(--color-fg-80);
  overflow-y: hidden;
}

.editor-panel-content {
  overflow-y: auto;
  height: 100%;
}
