.blocks-container {
  padding: var(--spacing-04x) var(--spacing-03x);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
  color: var(--color-fg-40);
}

.blocks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(120px, 1fr));
  grid-gap: 1rem;
  width: 100%;
  overflow: auto;
  height: 75vh;
}

.new-block > button {
  height: 120px;
  width: 120px;
  margin-bottom: var(--spacing-03x);
  display: flex;
  flex-direction: column;
}

.new-block > button > svg {
  width: 24px;
  height: 24px;
}
