.fallback-container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-04x);
}

.intro-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-01x);
}

.intro-text > span {
  font-weight: var(--font-weight-semi-bold);
}

.stack-name {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-02x);
}

.fallback-stack {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-03x);
  align-items: flex-end;
}

.fallback-stack > div {
  width: 130px;
}
