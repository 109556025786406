.input-increment-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-04x);
}

.input-incremental {
  width: var(--spacing-11x);
}
