.form-wrapper {
  display: flex;
  gap: var(--spacing-02x);
  width: 100%;
}

.field-wrapper {
  display: flex;
  gap: var(--spacing-02x);
  max-width: 800px;
  width: 100%;
}

.input-field {
  font-size: var(--font-family-s);
  padding: 0;
  width: 100%;
}
