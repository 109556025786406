.form-wrapper {
  display: flex;
  gap: 8px;
}

.form-wrapper > :first-child {
  flex-grow: 1;
}

.form-wrapper > :nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
